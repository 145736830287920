import React from 'react';
import {
  Caption,
  LazyComponent,
  ServiceMessage,
  Margin,
  Heading,
  styled,
} from '@kivra/react-components';
import type { Campaign, ClientStatusType } from '@sdk/campaigns';
import { ApiResponseError } from '@kivra/common';
import { captureException } from '@kivra/log';
import { useServiceCaller } from '../../util/useServiceCaller';
import { getCampaignStatistics } from '../../util/handleUnauthorizedErrorWrapper';
import { statisticStatusCopy } from '../../util/copyKeys';
import { getDayMonthTimeDate, getTimePeriodText } from '../../util/dates';
import { getCopy } from '../../util/copy';

interface Props {
  campaign: Campaign;
}

export const Statistics = ({ campaign }: Props) => {
  const [serviceState] = useServiceCaller(
    () =>
      getCampaignStatistics(
        campaign.organization.key,
        campaign.sender.key,
        campaign.id
      ),
    true
  );
  return (
    <>
      <Heading size="small">{getStatisticsText(campaign.clientStatus)}</Heading>
      <ItalicCaption gutterBottom={true} color="$text-secondary">
        {serviceState.status === 'success' &&
          getUpdatedAtText(serviceState.response.lastUpdatedCampaign, campaign)}
      </ItalicCaption>
      {serviceState.status === 'error' ? (
        <Margin bottom={12}>
          <ServiceMessage
            variant={getServiceMessageVariant(serviceState.error)}
            title={getCopy(getServiceMessageCopy(serviceState.error))}
          />
        </Margin>
      ) : (
        <LazyComponent
          Component={() =>
            import('./Illustrations').then(({ Illustrations }) => Illustrations)
          }
          onError={error => {
            captureException(error);
            throw error;
          }}
          ErrorComponent={() => (
            <>
              {serviceState.status === 'success' && (
                <Margin bottom={12}>
                  <ServiceMessage
                    variant="error"
                    title={getCopy('campaigns__statistics_error')}
                  />
                </Margin>
              )}
            </>
          )}
          render={Illustrations => (
            <Illustrations
              activePeriod={getTimePeriodText(
                campaign.activeFrom,
                campaign.attachTo
              )}
              hideClickStats={!campaign.destinationUrl && !campaign.useSso}
              documentType={campaign.documentType}
              statistics={serviceState.response}
            />
          )}
        />
      )}
    </>
  );
};

const getUpdatedAtText = (lastUpdatedCampaign: string, campaign: Campaign) => {
  if (campaign.clientStatus === 'previous') {
    if (!campaign.activeFrom || !campaign.activeTo) {
      return '';
    }
    return `${getDayMonthTimeDate(campaign.activeFrom)} - ${getDayMonthTimeDate(
      campaign.activeTo
    )}`;
  }
  return `${getCopy(
    'campaigns__statistics_updated_last'
  )}: ${getDayMonthTimeDate(lastUpdatedCampaign)}.`;
};

const getStatisticsText = (campaignStatus: ClientStatusType) => {
  if (campaignStatus === 'draft' || campaignStatus === 'upcoming') {
    return '';
  }
  return getCopy('campaigns__statistics_for', {
    status: getCopy(statisticStatusCopy[campaignStatus]).toLocaleLowerCase(),
  });
};

const getServiceMessageVariant = (error: ApiResponseError) =>
  isTooEarlyWarning(error) ? 'info' : 'error';

const getServiceMessageCopy = (error: ApiResponseError) => {
  return isTooEarlyWarning(error)
    ? 'campaings__statistics_content_error'
    : 'campaigns__statistics_error';
};

const ItalicCaption = styled(Caption)({
  fontStyle: 'italic',
});

const isTooEarlyWarning = (error: ApiResponseError) =>
  ApiResponseError.isUnavailableForLegalReasons(error) ||
  ApiResponseError.isWaitingForCampaignStatistics(error);
