import React from 'react';
import { Caption, Heading, Margin, styled } from '@kivra/react-components';
import { getDefaultPrivateRoute, goBack } from '../../routes/history';
import { Preview } from '../../components/preview';
import { useCampaign } from '../../context/campaignContext';
import { Statistics } from '../../components/statistics/Statistics';
import { getCopy } from '../../util/copy';
import { CampaignReadonlyDetails } from '../../components/CampaignReadonlyDetails';
import { Main } from '../../components/campaignLayout/Main';
import { SideBar } from '../../components/campaignLayout/SideBar';
import { Content } from '../../components/campaignLayout/Content';
import { InformationSurface } from '../../components/InformationSurface';
import { DetailsTopBar } from './DetailsTopBar';

export const CampaignDetails = () => {
  const { campaign } = useCampaign();

  const closeCampaign = () => {
    goBack(
      getDefaultPrivateRoute(campaign.organization.key, campaign.sender.key)
    );
  };

  return (
    <>
      <DetailsTopBar closeCampaign={closeCampaign} />
      <Main>
        <SideBar>
          {campaign.clientStatus === 'previous' && (
            <Margin top={16} left={8} right={8}>
              <InformationSurface data-test-id="DetailsCannotBeEdited">
                <Caption>{getCopy('campaigns__cannot_be_edited')}</Caption>
              </InformationSurface>
            </Margin>
          )}
          <SideBarContainer data-test-id="DetailsSideBar">
            <CampaignReadonlyDetails campaign={campaign} />
          </SideBarContainer>
        </SideBar>
        <Content>
          <StatisticsPadding>
            <Heading size="medium">{getCopy('campaigns__overview')}</Heading>
            <Statistics campaign={campaign} />
          </StatisticsPadding>
          <Preview campaign={campaign} />
        </Content>
      </Main>
    </>
  );
};

const SideBarContainer = styled('div')({
  padding: '$spacing-20',
});

const StatisticsPadding = styled('div')({
  backgroundColor: '$background-secondary',
  paddingTop: '$spacing-32',
  paddingLeft: '$spacing-32',
  paddingRight: '$spacing-8',
  paddingBottom: '$spacing-8',
});
