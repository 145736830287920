import React from 'react';
import { Select, styled, css } from '@kivra/react-components';
import { useEditorFonts } from '../FontProvider';

type Props = {
  font: string;
  onChange: (font: string) => void;
};

type Option = {
  value: string;
  label: string;
};

const EMPTY_VALUE = '';

export const FontSelector = ({ font, onChange }: Props) => {
  const { fonts } = useEditorFonts();

  const fontOptions = fonts.map((fontName) => ({
    label: fontName,
    value: fontName,
  }));

  return (
    <Wrapper>
      <Select
        size="small"
        value={fonts.includes(font) ? font : EMPTY_VALUE}
        onChange={(value) => onChange(value)}
        options={fontOptions}
        ValueComponent={ValueComponent}
        OptionComponent={OptionComponent}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  maxWidth: 150,
  marginTop: '-$spacing-12',
  '& button': {
    height: 28,
    padding: ['$spacing-2', '$spacing-4'],
    borderRadius: '$radius-small',
  },
});

const OptionComponent = ({
  option,
  selected,
  active,
}: {
  option: Option;
  selected?: boolean;
  active?: boolean;
}) => {
  const { value } = option;
  return (
    <option
      key={value}
      label={value}
      value={value}
      className={css({
        display: 'flex',
        alignItems: 'center',
        minHeight: 42,
        paddingLeft: '$spacing-24',
        paddingRight: '$spacing-8',
        fontSize: '16px',
        fontFamily: value,
        fontWeight: selected ? 'bold' : 'normal',
        backgroundColor: active ? '$border-subtle' : undefined,
      })}
    >
      <ValueComponent option={option} />
    </option>
  );
};
const ValueComponent = ({ option }: { option?: Option }) => {
  return (
    <div
      style={{
        fontFamily: option?.value,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {option?.label}
    </div>
  );
};
