import React, { ComponentProps } from 'react';
import { css, NavDrawer } from '@kivra/react-components';

type Props = ComponentProps<typeof NavDrawer>;

export const CampaignsNavDrawer: React.FC<Props> = (props) => {
  return (
    <div
      className={css({
        minWidth: '$nav-drawer-width',
        $medium: {
          minWidth: 0,
        },
      })}
    >
      <NavDrawer {...props}>{props.children}</NavDrawer>
    </div>
  );
};
