import React from 'react';
import { useSender } from '../../context/senderContext';
import { UserImage } from './UserImage';
import { AuthenticatedEntityLayout } from './AuthenticatedEntityLayout';

type Props = {
  subtitle?: string;
};

export const SenderLogoAndName = ({ subtitle }: Props) => {
  const sender = useSender();
  return (
    <AuthenticatedEntityLayout
      icon={<UserImage src={sender.iconUrl} alt="user avatar" />}
      subtitle={subtitle}
      title={sender.name}
    />
  );
};
