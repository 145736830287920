import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Dashboard } from '../pages/dashboard/Dashboard';
import { CampaignPage } from '../pages/campaign/CampaignPage';
import { NoMatch } from '../pages/404/NoMatch';
import { SenderProvider } from '../context/SenderProvider';

export const SenderRoutes = () => {
  return (
    <SenderProvider>
      <Switch>
        <Route
          exact
          path={[
            '/organization/:organizationId/sender/:senderKey/:dashboardType',
          ]}
          component={Dashboard}
        />
        <Route
          exact
          path={[
            '/organization/:organizationId/sender/:senderKey/campaign/:campaignId/:pageType',
          ]}
          component={CampaignPage}
        />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </SenderProvider>
  );
};
