import { getCopyString } from '@kivra/copy-consumer';
import { Copy, GetCopyArguments } from '../types/copy';
import { CopyKeysWithoutArgs } from '../types/copyKeys';

let copy: Copy;

export const setCopy = (newCopy: Copy) => {
  copy = newCopy;
};

export const getCopy = (...args: GetCopyArguments | [CopyKeysWithoutArgs]) => {
  if (!copy) {
    throw new Error('getCopy used before setCopy');
  }
  return getCopyString<GetCopyArguments>(copy as Copy)(
    ...(args as GetCopyArguments)
  );
};
