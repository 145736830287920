import React from 'react';
import { css, Flex, Heading } from '@kivra/react-components';
import { useStaticOrigin } from '../context/globalContext';

interface Props {
  title: string;
  image: string | null;
}

export const TitleImageCell = ({ title, image }: Props) => {
  const staticOrigin = useStaticOrigin();
  const fallbackImage = `${staticOrigin}/assets/illustrations/campaigns/fallback-image.png`;
  return (
    <Flex alignItems="center">
      <img
        alt="title"
        className={css({ width: 90, marginRight: '$spacing-10' })}
        src={image || fallbackImage}
      />
      <Heading size="xsmall">{title}</Heading>
    </Flex>
  );
};
