import {
  TopBar,
  useIsMediumScreenSize,
  HamburgerMenu,
} from '@kivra/react-components';
import React from 'react';
import { getDefaultPrivateRoute, goTo } from '../routes/history';
import { useCurrentOrganization } from '../context/sendersContext';
import { getCopy } from '../util/copy';
import {
  UserProfileDrawer,
  UserProfileDrawerContainer,
} from './userProfile/UserProfileDrawer';

interface Props {
  drawerOpen: boolean;
  handleDrawerToggle?: () => void;
  isOrganizationOverview?: boolean;
}

export const DashboardTopBar = ({
  drawerOpen,
  handleDrawerToggle,
  isOrganizationOverview = false,
}: Props) => {
  const isMediumScreen = useIsMediumScreenSize();
  const { organizationId } = useCurrentOrganization();
  const userProfileElement = (
    <UserProfileDrawerContainer>
      <UserProfileDrawer
        isOrganizationOverview={isOrganizationOverview}
        onSenderClicked={(senderKey: string) =>
          goTo(getDefaultPrivateRoute(organizationId, senderKey))
        }
      />
    </UserProfileDrawerContainer>
  );
  const toggleMenuElement = isMediumScreen && (
    <HamburgerMenu
      openText={getCopy('sender_portal__menu')}
      closeText={getCopy('sender_portal__general_close')}
      onClick={handleDrawerToggle}
      isOpen={drawerOpen}
    />
  );

  return (
    <TopBar
      hideLogo={isMediumScreen}
      alignLogo="left"
      logoText="Campaigns"
      startAdornment={toggleMenuElement}
      endAdornment={userProfileElement}
      position="relative"
      logoHref="#"
    />
  );
};
