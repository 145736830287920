import React from 'react';
import { Button, Caption, Flex, Margin } from '@kivra/react-components';
import { getCopy } from '../util/copy';

type Props = {
  current: number;
  total: number;
  onClick: () => void;
};

export const LoadMore = ({ current, onClick, total }: Props) => {
  if (current >= total) {
    return null;
  }
  return (
    <Flex direction="column" alignItems="center">
      <Button onClick={onClick}>{getCopy('campaigns__load_more')}</Button>
      <Margin top={12}>
        <Caption color="$text-secondary">
          {getCopy('campaigns__out_of', { current, total })}
        </Caption>
      </Margin>
    </Flex>
  );
};
