import type { TextOptions } from '@sdk/campaigns';
import { Flex } from '@kivra/react-components';
import { BoldIcon16, ItalicIcon16, UnderlineIcon16 } from '@kivra/react-icons';
import React from 'react';
import { Selectable } from './Selectable';

type Props = Pick<TextOptions, 'bold' | 'italic' | 'underline'> & {
  onChange: (
    styles: Pick<TextOptions, 'bold' | 'italic' | 'underline'>
  ) => void;
};

const styles: (keyof Omit<Props, 'onChange'>)[] = [
  'bold',
  'italic',
  'underline',
];

const icons: Record<keyof Omit<Props, 'onChange'>, JSX.Element> = {
  bold: <BoldIcon16 color="currentColor" />,
  italic: <ItalicIcon16 color="currentColor" />,
  underline: <UnderlineIcon16 color="currentColor" />,
};

export const StyleSelector = (props: Props) => {
  return (
    <Flex>
      {styles.map(style => (
        <Selectable
          key={style}
          selected={props[style]}
          onClick={() => props.onChange({ ...props, [style]: !props[style] })}
        >
          {icons[style]}
        </Selectable>
      ))}
    </Flex>
  );
};
