import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { senderStorage } from '../util/senderStorage';
import { getIsSsoEnabled } from '../util/handleUnauthorizedErrorWrapper';
import { useCurrentOrganization, useGetSenders } from './sendersContext';
import { SenderContextProvider } from './senderContext';

type Props = {
  children?: React.ReactNode;
};

export const SenderProvider = ({ children }: Props) => {
  const getSender = useGetSenders();
  const { organizationId } = useCurrentOrganization();
  const senderKey = useRouteMatch<{ senderKey: string }>().params.senderKey;
  const [isSsoEnabled, setIsSsoEnabled] = useState(false);
  const sender = getSender(senderKey);

  useEffect(() => {
    getIsSsoEnabled(organizationId, senderKey).then(({ hasSso }) => {
      setIsSsoEnabled(hasSso);
    });
    senderStorage.set({ sender: senderKey, organizationId });
  }, [senderKey]);

  if (!sender) {
    return <Redirect to="/access-denied-sender" />;
  }
  return (
    <SenderContextProvider value={{ sender: sender, isSsoEnabled }}>
      {children}
    </SenderContextProvider>
  );
};
