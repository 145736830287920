import React from 'react';
import { Body, Caption, Flex } from '@kivra/react-components';

type Props = {
  icon: JSX.Element;
  title: string;
  subtitle?: string;
};

export const AuthenticatedEntityLayout = ({ icon, subtitle, title }: Props) => {
  return (
    <Flex justifyContent="flex-start">
      {icon}
      <Flex direction="column" justifyContent="center" alignItems="flex-start">
        <Body size="medium">{title}</Body>
        {subtitle && <Caption color="$text-secondary">{subtitle}</Caption>}
      </Flex>
    </Flex>
  );
};
