import type { ClientStatusType } from '@sdk/campaigns';
import { CampaignAction } from '../components/CampaignActions';

export const campaignActionsByStatus: Record<
  ClientStatusType,
  CampaignAction[]
> = {
  ongoing: ['edit', 'duplicate', 'unpublish-ongoing', 'delete'],
  upcoming: ['edit', 'duplicate', 'unpublish-upcoming', 'delete'],
  draft: ['edit', 'duplicate', 'delete'],
  previous: ['duplicate', 'delete'],
};
