import React, { createContext, useContext, useState } from 'react';
import {
  Body,
  css,
  Dialog,
  FullPageLoader,
  zIndex,
} from '@kivra/react-components';
import { AnimatePresence, motion } from 'framer-motion';
import { WarningCircleIcon48 } from '@kivra/react-icons';
import { getCopy } from '../util/copy';

type ErrorMessage = {
  title: string;
  body: string;
  dismiss: string;
};

const LoadingOverlayContext = createContext({
  isLoading: false,
  setIsLoading: (isLoading: boolean) => {
    console.error(
      'LoadingOverlayContext setIsLoading not implemented, attempted to set:',
      isLoading
    );
  },
  setErrorMessage: (msg?: ErrorMessage) => {
    console.error(
      'LoadingOverlayContext setErrorMessage not implemented, attempted to set:',
      msg
    );
  },
});
export const useLoadingOverlay = () => useContext(LoadingOverlayContext);

type Props = {
  children?: React.ReactNode;
};

export const LoadingOverlayProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const genericErrorMessage = {
    title: getCopy('error_generic__title'),
    body: getCopy('campaigns__dialog_error_generic_body'),
    dismiss: getCopy('btn__ok_dialog'),
  };
  return (
    <>
      <LoadingOverlayContext.Provider
        value={{
          isLoading,
          setIsLoading,
          setErrorMessage: (msg: ErrorMessage = genericErrorMessage) =>
            setErrorMessage(msg),
        }}
      >
        {children}
      </LoadingOverlayContext.Provider>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={css({
              zIndex: zIndex.modal,
              background: 'rgba(255,255,255,0.47)',
              backdropFilter: 'blur(1px)',
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              top: 0,
            })}
          >
            <FullPageLoader />
          </motion.div>
        )}
      </AnimatePresence>
      {errorMessage !== undefined && (
        <Dialog.Passive
          dataTestId="LoadingOverlayError"
          open={true}
          onClose={() => setErrorMessage(undefined)}
          title={errorMessage.title || genericErrorMessage.title}
          ariaLabelCloseIcon={errorMessage.dismiss}
          largeIcon={<WarningCircleIcon48 color={'currentColor'} />}
          onDismissFocusRef={undefined}
        >
          <Body size="medium">{errorMessage.body}</Body>
        </Dialog.Passive>
      )}
    </>
  );
};
