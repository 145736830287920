import React from 'react';
import { Caption, Margin } from '@kivra/react-components';
import { InformationSurface } from '../../../../components/InformationSurface';
import { useCampaignStatus } from '../../../../context/campaignContext';
import { getCopy } from '../../../../util/copy';

export const EditInformation = () =>
  useCampaignStatus() === 'ongoing' ? (
    <Margin all={8}>
      <InformationSurface>
        <Caption>{getCopy('campaigns__ongoing_edit_message')}</Caption>
      </InformationSurface>
    </Margin>
  ) : null;
