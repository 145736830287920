import { styled, isPropValid } from '@kivra/react-components';
import { ImgHTMLAttributes } from 'react';

const sizes = {
  small: 32,
  medium: 41,
};

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  size?: keyof typeof sizes;
}

export const UserImage = styled('img', {
  forwardProp: (prop) => isPropValid(prop),
})<Props>(({ size = 'medium' }: Props) => ({
  width: sizes[size],
  height: sizes[size],
  borderRadius: '100%',
  marginRight: '$spacing-10',
}));
