import React, { createContext, useContext, useState } from 'react';
import {
  Flex,
  ServiceMessage,
  AnimatedServiceMessage,
  styled,
  zIndex,
  StyleException,
} from '@kivra/react-components';

type ServiceMessageData = {
  title: string;
  body: string;
  variant: 'success' | 'info' | 'error';
  id: string;
  fadeout?: boolean;
};

const ServiceMessageContext = createContext({
  addServiceMessage: (message: Omit<ServiceMessageData, 'id'>) => {
    console.error('addServiceMessage not implemented', message);
  },
});
export const useServiceMessage = () => useContext(ServiceMessageContext);

type Props = {
  children?: React.ReactNode;
};

export const ServiceMessageProvider = ({ children }: Props) => {
  const [messages, setMessages] = useState<ServiceMessageData[]>([]);

  const addMessage = (message: Omit<ServiceMessageData, 'id'>) => {
    const id = new Date().valueOf().toString();
    setMessages([...messages, { ...message, id }]);
    if (message.fadeout) {
      fadeout(id);
    }
    return () => dismissMessage(id);
  };

  const dismissMessage = (messageIdToRemove: string) => {
    setMessages(messages =>
      messages.filter(({ id }: ServiceMessageData) => id !== messageIdToRemove)
    );
  };

  const fadeout = (id: string) => {
    setTimeout(() => dismissMessage(id), 2000);
  };

  return (
    <>
      <ServiceMessageContext.Provider value={{ addServiceMessage: addMessage }}>
        {children}
      </ServiceMessageContext.Provider>
      <Wrapper justifyContent="flex-end" alignItems="center" direction="column">
        <AnimatedServiceMessage>
          {messages.map(
            ({
              title,
              body,
              variant,
              id,
              fadeout = false,
            }: ServiceMessageData) => {
              return (
                <ServiceMessage
                  fullWidth={true}
                  key={id}
                  variant={variant}
                  title={title}
                  onCloseRequest={
                    !fadeout ? () => dismissMessage(id) : undefined
                  }
                >
                  {body}
                </ServiceMessage>
              );
            }
          )}
        </AnimatedServiceMessage>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Flex)({
  zIndex: zIndex.snackbar,
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: ['$spacing-32', 0],
  ['& > *']: {
    width: '100%',
    padding: [0, '$spacing-32'],
    marginTop: StyleException('8px !important'),
    pointerEvents: 'all',
  },
});
