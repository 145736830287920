import React from 'react';
import { Flex } from '@kivra/react-components';
import {
  AlignLeftIcon16,
  AlignCenterIcon16,
  AlignRightIcon16,
} from '@kivra/react-icons';
import { HorizontalAlignment } from '../../../types/alignment';
import { Selectable } from './Selectable';

type Props = {
  alignment: HorizontalAlignment;
  onChange: (alignment: HorizontalAlignment) => void;
};

const icons: Record<HorizontalAlignment, JSX.Element> = {
  left: <AlignLeftIcon16 color="currentColor" />,
  center: <AlignCenterIcon16 color="currentColor" />,
  right: <AlignRightIcon16 color="currentColor" />,
};

const alignments: HorizontalAlignment[] = ['left', 'center', 'right'];

export const AlignmentSelector = (props: Props) => {
  return (
    <Flex>
      {alignments.map((alignment) => (
        <Selectable
          key={alignment}
          selected={alignment === props.alignment}
          onClick={() => props.onChange(alignment)}
          deselectable={false}
        >
          {icons[alignment]}
        </Selectable>
      ))}
    </Flex>
  );
};
