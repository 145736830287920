import React from 'react';
import { SenderType } from '@kivra/types/sender';
import type { ClientStatusType } from '@sdk/campaigns';
import { isReceiptSender } from '../../../../util/senderType';
import { TitleField } from './fields/TitleField';
import { DocumentTypeField } from './fields/DocumentTypeField';
import { PositionField } from './fields/PositionField';
import { ImageField } from './fields/ImageField';
import { LinkField } from './fields/LinkField';
import { CampaignField } from './fields/fieldType';
import { CampaignDatesBlocks } from './fields/CampaignDatesBlocks';
import { TargetGroupField } from './fields/TargetGroupField';

const receiptForm: CampaignField[] = [
  TitleField,
  DocumentTypeField,
  ImageField,
  CampaignDatesBlocks,
  LinkField,
];
const postalForm: CampaignField[] = [
  TitleField,
  DocumentTypeField,
  PositionField,
  ImageField,
  CampaignDatesBlocks,
  LinkField,
  TargetGroupField,
];

type Props = {
  senderType: SenderType;
  campaignStatus: ClientStatusType;
};

export const Editor = React.memo(({ senderType, campaignStatus }: Props) => {
  const fields = isReceiptSender(senderType) ? receiptForm : postalForm;
  return (
    <form>
      {fields.map(field =>
        React.createElement(field, { campaignStatus, key: field.name })
      )}
    </form>
  );
});
