import React, { useState, useRef } from 'react';
import {
  IconButton,
  MenuList,
  MenuItem,
  Popover,
} from '@kivra/react-components';
import { MenuDotsIcon } from '@kivra/react-icons';
import type { Action, CampaignAction } from './CampaignActions';

type Props = {
  menuActions: CampaignAction[];
  actions: Action[];
};

export const CampaignPopoverMenu = ({ menuActions, actions }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <IconButton
        ref={anchorRef}
        variant="link"
        icon={MenuDotsIcon}
        aria-expanded={isOpen}
        aria-haspopup
        aria-controls="popover"
        onClick={() => setIsOpen(true)}
        dataTestId="CampaignPopoverMenu"
      />
      <Popover
        isOpen={isOpen}
        anchorRef={anchorRef}
        onClose={() => setIsOpen(false)}
        drawerOnSmallScreenSize={false}
      >
        <MenuList>
          {menuActions.map((actionKey) => {
            const action = actions.find(({ key }) => key === actionKey);
            return !!action ? (
              <MenuItem
                key={actionKey}
                onClick={() => {
                  setIsOpen(false);
                  action.onClick();
                }}
                iconSmall={action.icon}
                title={action.text}
                size="small"
              />
            ) : null;
          })}
        </MenuList>
      </Popover>
    </>
  );
};
