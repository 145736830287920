import { fetchCopy, setPageLanguage } from '@kivra/copy-consumer';
import { Copy } from '../types/copy';

const languages = {
  localPath: {
    sv: '/sv.json',
    en: '/en.json',
  },
};

export function getCopy(): Promise<Copy> {
  return fetchCopy<Copy>(languages.localPath, 'se').then(
    ({ copy, language }) => {
      setPageLanguage(language);
      return copy;
    }
  );
}
