import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  SenderMetricsTime: any;
};

export type Query = {
  __typename?: 'Query';
  organization: Organization;
  /** @deprecated No longer supported */
  organizationByKey: Organization;
  organizations: Array<Organization>;
  /** @deprecated No longer supported */
  organizationsByKeys: Array<Organization>;
  sender: Sender;
  /** @deprecated No longer supported */
  senderByKey: Sender;
  system: Maybe<SystemInfo>;
};


export type QueryOrganizationArgs = {
  key: Scalars['ID'];
};


export type QueryOrganizationByKeyArgs = {
  key: Scalars['ID'];
};


export type QueryOrganizationsArgs = {
  keys: Array<Scalars['ID']>;
};


export type QueryOrganizationsByKeysArgs = {
  keys: Array<Scalars['ID']>;
};


export type QuerySenderArgs = {
  key: Scalars['ID'];
};


export type QuerySenderByKeyArgs = {
  key: Scalars['ID'];
};

export type Organization = {
  __typename?: 'Organization';
  /** Timestamp when organization was created */
  createdAt: Scalars['DateTime'];
  /** List of ID Providers for organization */
  idProviders: Array<Scalars['String']>;
  /** Not returned by backend - but should be */
  key: Scalars['ID'];
  /** Members of the organization */
  members: Array<Membership>;
  /** Name of the organization  */
  name: Scalars['String'];
  /** The senders that belong to the organization */
  senders: Array<Sender>;
};

export type Membership = {
  __typename?: 'Membership';
  /** ID of the member */
  id: Scalars['ID'];
  /** Email of the memeber */
  idFieldValue: Scalars['String'];
  /** Identity provider of use */
  idProvider: Scalars['String'];
};

export type Sender = {
  __typename?: 'Sender';
  /** Sender branding information */
  branding: Branding;
  /** Entry point to sender campaigns */
  campaigns: Campaigns;
  contactFields: Array<ContactField>;
  /** Sender specific features */
  features: Array<SenderFeature>;
  key: Scalars['ID'];
  metrics: Maybe<SenderMetrics>;
  name: Scalars['String'];
  /** Type of sender */
  type: SenderType;
};

/**
 * Branding for a Sender. This will be used to style the Sender pages across the
 * applications.
 */
export type Branding = {
  __typename?: 'Branding';
  /** The preferred background. */
  background: Background;
  /** The main logo of a sender. */
  logo: Image;
  /** The last time the branding was updated. */
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type Background = {
  __typename?: 'Background';
  /** The image that is shown on the header. */
  image: Maybe<Image>;
  /** The large image that is shown on the header. */
  largeImage: Maybe<Image>;
};

export type Image = {
  __typename?: 'Image';
  /** A URL to the image that is publicly accessible. */
  publicUrl: Scalars['String'];
};

/** Campaigns related information for a sender */
export type Campaigns = {
  __typename?: 'Campaigns';
  /** Document types that can be attached to campaigns */
  documentTypes: Array<LocalizedLabel>;
};

export type LocalizedLabel = {
  __typename?: 'LocalizedLabel';
  key: Scalars['String'];
  label: Scalars['String'];
  language: Scalars['String'];
};

export type ContactField = {
  __typename?: 'ContactField';
  key: Scalars['String'];
  /** @deprecated No longer supported */
  label: ContactFieldLabel;
  localizedLabel: ContactLocalizedLabel;
  opts: ContactFieldOpts;
  /** @deprecated No longer supported */
  order: Scalars['Int'];
  type: ContactFieldType;
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  /** @deprecated No longer supported */
  visible: Maybe<Scalars['Boolean']>;
};

export type ContactFieldLabel =
  | 'CHAT'
  | 'EMAIL'
  | 'FAQ'
  | 'MY_PAGES'
  | 'PHONE'
  | 'TO_THE_APP'
  | 'WEBSITE'
  | 'WRITE_TO_US';

export type ContactLocalizedLabel = {
  __typename?: 'ContactLocalizedLabel';
  key: ContactFieldLabel;
  label: Scalars['String'];
  language: Scalars['String'];
};

export type ContactFieldOpts = {
  __typename?: 'ContactFieldOpts';
  ssoEnabled: Maybe<Scalars['Boolean']>;
};

export type ContactFieldType =
  | 'EMAIL'
  | 'LINK'
  | 'PHONE';

/** Sender feature configuration */
export type SenderFeature = {
  __typename?: 'SenderFeature';
  /** Feature specific configuration */
  config: FeatureConfig;
  /** Whether the feature is enabled */
  enabled: Scalars['Boolean'];
  /** Feature name */
  feature: Scalars['String'];
  /** Last update time */
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type FeatureConfig = ConfigSso;

export type ConfigSso = {
  __typename?: 'ConfigSso';
  /** Service provider URL */
  serviceProviderUrl: Maybe<Scalars['String']>;
};

export type SenderMetrics = {
  __typename?: 'SenderMetrics';
  daily: SenderMetricsDaily;
  meta: SenderMetricsMeta;
  monthly: SenderMetricsMonthly;
  total: SenderMetricsTotalRow;
};


export type SenderMetricsDailyArgs = {
  opts: InputMaybe<SenderMetricsQueryOpts>;
};


export type SenderMetricsMonthlyArgs = {
  opts: InputMaybe<SenderMetricsQueryOpts>;
};

export type SenderMetricsQueryOpts = {
  cursor: InputMaybe<Scalars['SenderMetricsTime']>;
  limit: InputMaybe<Scalars['Int']>;
  range: InputMaybe<DateRangeInput>;
  sort: InputMaybe<Sort>;
};

/**
 * from: inclusive
 * to: exclusive
 */
export type DateRangeInput = {
  from: Scalars['SenderMetricsTime'];
  to: Scalars['SenderMetricsTime'];
};

/** Sort, specifies how to sort response, only applicable where time is present. */
export type Sort =
  | 'ASC'
  | 'DESC';

export type SenderMetricsDaily = {
  __typename?: 'SenderMetricsDaily';
  data: Array<SenderMetricsDailyRow>;
  pageInfo: PageInfo;
  summary: SenderMetricsSummary;
};

export type SenderMetricsDailyRow = {
  __typename?: 'SenderMetricsDailyRow';
  avoidedCo2Emissions: Scalars['Float'];
  contentNonPayableSent: Scalars['Int'];
  contentOpened: Scalars['Int'];
  contentSent: Scalars['Int'];
  date: Scalars['SenderMetricsTime'];
  invoicesPaid: Scalars['Int'];
  invoicesSent: Scalars['Int'];
  openRate: Scalars['Float'];
  payRate: Scalars['Float'];
  savedTrees: Scalars['Float'];
  uniqueRecipients: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  count: Scalars['Int'];
  endDate: Maybe<Scalars['SenderMetricsTime']>;
  hasNext: Scalars['Boolean'];
  startDate: Maybe<Scalars['SenderMetricsTime']>;
};

export type SenderMetricsSummary = {
  __typename?: 'SenderMetricsSummary';
  avoidedCo2Emissions: Scalars['Float'];
  contentNonPayableSent: Scalars['Int'];
  contentOpened: Scalars['Int'];
  contentSent: Scalars['Int'];
  invoicesPaid: Scalars['Int'];
  invoicesSent: Scalars['Int'];
  openRate: Scalars['Float'];
  payRate: Scalars['Float'];
  savedTrees: Scalars['Float'];
};

export type SenderMetricsMeta = {
  __typename?: 'SenderMetricsMeta';
  earliestAvailableDate: Maybe<Scalars['SenderMetricsTime']>;
  latestAvailableDate: Maybe<Scalars['SenderMetricsTime']>;
};

export type SenderMetricsMonthly = {
  __typename?: 'SenderMetricsMonthly';
  data: Array<SenderMetricsMonthlyRow>;
  pageInfo: PageInfo;
  summary: SenderMetricsSummary;
};

export type SenderMetricsMonthlyRow = {
  __typename?: 'SenderMetricsMonthlyRow';
  avoidedCo2Emissions: Scalars['Float'];
  contentNonPayableSent: Scalars['Int'];
  contentOpened: Scalars['Int'];
  contentSent: Scalars['Int'];
  invoicesPaid: Scalars['Int'];
  invoicesSent: Scalars['Int'];
  month: Scalars['SenderMetricsTime'];
  openRate: Scalars['Float'];
  payRate: Scalars['Float'];
  savedTrees: Scalars['Float'];
  uniqueRecipients: Scalars['Int'];
};

export type SenderMetricsTotalRow = {
  __typename?: 'SenderMetricsTotalRow';
  avoidedCo2Emissions: Scalars['Float'];
  contentNonPayableSent: Scalars['Int'];
  contentOpened: Scalars['Int'];
  contentSent: Scalars['Int'];
  invoicesPaid: Scalars['Int'];
  invoicesSent: Scalars['Int'];
  openRate: Scalars['Float'];
  payRate: Scalars['Float'];
  savedTrees: Scalars['Float'];
  uniqueRecipients: Scalars['Int'];
};

export type SenderType =
  | 'store'
  | 'tenant';

export type SystemInfo = {
  __typename?: 'SystemInfo';
  /** Server version */
  version: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  inviteMemberToOrganization: Organization;
  removeMemberFromOrganization: Organization;
  updateSenderProfile: Sender;
};


export type MutationInviteMemberToOrganizationArgs = {
  email: Scalars['String'];
  identityProvider: Scalars['String'];
  organizationKey: Scalars['String'];
  organizationName: Scalars['String'];
};


export type MutationRemoveMemberFromOrganizationArgs = {
  memberKey: Scalars['String'];
  organizationKey: Scalars['String'];
};


export type MutationUpdateSenderProfileArgs = {
  backgroundImage: InputMaybe<BrandingBackgroundImageInput>;
  contactFields: InputMaybe<Array<ContactFieldInput>>;
  largeBackgroundImage: InputMaybe<BrandingBackgroundImageInput>;
  senderKey: Scalars['ID'];
};

export type BrandingBackgroundImageInput = {
  type: ImageInputType;
  value: Scalars['String'];
};

/** Format of image input */
export type ImageInputType =
  /** A base 64 encoded string */
  | 'BASE_64'
  /** No image defined */
  | 'NO_IMAGE';

export type ContactFieldInput = {
  label: ContactFieldLabel;
  opts: InputMaybe<ContactFieldOptsInput>;
  type: ContactFieldType;
  updatedAt: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ContactFieldOptsInput = {
  ssoEnabled: InputMaybe<Scalars['Boolean']>;
};

/**
 * from: inclusive
 * to: exclusive
 */
export type DateRange = {
  __typename?: 'DateRange';
  from: Scalars['SenderMetricsTime'];
  to: Scalars['SenderMetricsTime'];
};

export type SenderQueryVariables = Exact<{
  senderKey: Scalars['ID'];
}>;


export type SenderQuery = { __typename?: 'Query', sender: { __typename?: 'Sender', campaigns: { __typename?: 'Campaigns', documentTypes: Array<{ __typename?: 'LocalizedLabel', key: string, label: string }> } } };


export const SenderDocument = gql`
    query sender($senderKey: ID!) {
  sender(key: $senderKey) {
    campaigns {
      documentTypes {
        key
        label
      }
    }
  }
}
    `;

/**
 * __useSenderQuery__
 *
 * To run a query within a React component, call `useSenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSenderQuery({
 *   variables: {
 *      senderKey: // value for 'senderKey'
 *   },
 * });
 */
export function useSenderQuery(baseOptions: Apollo.QueryHookOptions<SenderQuery, SenderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SenderQuery, SenderQueryVariables>(SenderDocument, options);
      }
export function useSenderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SenderQuery, SenderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SenderQuery, SenderQueryVariables>(SenderDocument, options);
        }
export type SenderQueryHookResult = ReturnType<typeof useSenderQuery>;
export type SenderLazyQueryHookResult = ReturnType<typeof useSenderLazyQuery>;
export type SenderQueryResult = Apollo.QueryResult<SenderQuery, SenderQueryVariables>;