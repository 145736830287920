import React, { useState } from 'react';
import {
  Flex,
  Margin,
  Body,
  useOnMount,
  styled,
} from '@kivra/react-components';
import { getOrganization, getOrganizationSenders } from '@kivra/senders';
import { KivraIcon } from '@kivra/react-icons';
import { useConfig } from '../../context/globalContext';

type Props = {
  organizationId: string;
  onClick: () => void;
};

const Base = styled('div')({
  width: 130,
  textOverflow: 'ellipsis',
  margin: '$spacing-8',
  textAlign: 'center',
  borderRadius: '$radius-medium',
  boxShadow: '$shadow-default',
  padding: '$spacing-16',
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
  '.keyboardFocus &:focus': {
    outline: `2px auto $focus-border`,
  },
  '&:hover': {
    boxShadow: '$shadow-hover-list',
  },
});

export const OrganizationCard = ({ organizationId, onClick }: Props) => {
  const [iconUrl, setIconUrl] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const adminOrganizationId = useConfig()
    .sender_backoffice_admin_organization_id;

  useOnMount(() => {
    Promise.all([
      getOrganization(organizationId),
      getOrganizationSenders(organizationId),
    ]).then(([{ name }, senders]) => {
      setIconUrl(senders[0]?.iconUrl);
      setOrganizationName(name);
    });
  });

  if (organizationId === adminOrganizationId) {
    return (
      <Base role="button" onClick={onClick} tabIndex={0}>
        <Flex direction="column" alignItems="center" justifyContent="center">
          <Margin top={11} right={11} left={11} bottom={11}>
            <KivraIcon scale={2} />
          </Margin>
          <Body size="small">Kivra Backoffice Admin</Body>
        </Flex>
      </Base>
    );
  }

  if (!iconUrl) {
    return null;
  }

  return (
    <Base role="button" onClick={onClick} tabIndex={0}>
      <Flex direction="column" alignItems="center" justifyContent="center">
        {iconUrl && (
          <img width={70} height={70} alt={'Tenant icon'} src={iconUrl} />
        )}
        <Body size="small">{organizationName}</Body>
      </Flex>
    </Base>
  );
};
