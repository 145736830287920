import React from 'react';
import { Caption, css, Margin } from '@kivra/react-components';
import { UploadButton } from '../UploadButton';
import { getDayMonthYearText } from '../../../../../util/dates';
import { getStatusAreaTexts } from '../../../../../util/copyKeys';
import { getCopy } from '../../../../../util/copy';
import { useCampaign } from '../../../../../context/campaignContext';
import { I } from '../../../../../components/Italic';
import { InformationSurface } from '../../../../../components/InformationSurface';
import { FormErrorMessage } from '../FormErrorMessage';
import { StatusArea } from './StatusArea';
import {
  FileValidation,
  SegmentationChangeEvent,
  SegmentationUploadState,
} from './SegmentationUploadContainer';

interface Props {
  fileValidation?: FileValidation;
  onDeleteSegmentation(): void;
  onHandleFiles: (files: FileList) => Promise<SegmentationChangeEvent>;
  onUploadSegmentation(change: SegmentationChangeEvent): void;
  state: SegmentationUploadState;
}

export const Body = ({
  fileValidation,
  onDeleteSegmentation,
  onHandleFiles,
  onUploadSegmentation,
  state,
}: Props) => {
  const clientStatus = useCampaign(false).campaign?.clientStatus || 'draft';

  const uploadButtonElement = (
    <Margin bottom={16}>
      <UploadButton<SegmentationChangeEvent>
        disabled={clientStatus === 'ongoing'}
        validFileTypes=".csv"
        handleFiles={onHandleFiles}
        onChange={onUploadSegmentation}
      />
    </Margin>
  );

  const rejectedRowsWarning = fileValidation &&
    fileValidation?.rejectedRows > 0 && (
      <Margin top={8}>
        <InformationSurface>
          <Caption align="center">
            {getCopy('campaigns__segmenation_accepted_rejected_rows', {
              acceptedCount: fileValidation.acceptedRows,
              rejectedCount: fileValidation.rejectedRows,
            })}
          </Caption>
        </InformationSurface>
      </Margin>
    );

  switch (state.status) {
    case 'fetched':
      return (
        <>
          <Margin bottom={16}>
            <StatusArea
              label={state.segmentation.filename}
              status="complete"
              onClose={
                clientStatus !== 'ongoing' ? onDeleteSegmentation : undefined
              }
            />
          </Margin>
          <Caption align="center">
            <I>
              {`${getCopy('campaigns__uploaded')} `}
              <span className={css({ color: '$text-secondary' })}>
                {getDayMonthYearText(state.segmentation.uploadedAt)}
              </span>
            </I>
          </Caption>
          <Caption style={{ fontStyle: 'italic' }} align="center">
            {getCopy('campaigns__segmentation_count_label', {
              count: state.segmentation.rows,
            })}
          </Caption>
          {rejectedRowsWarning}
        </>
      );
    case 'preparingRequest':
    case 'uploading':
    case 'processing':
      const { label, helperText } = getStatusAreaTexts(state);
      return (
        <>
          <Margin bottom={16}>
            <StatusArea
              label={label}
              status="loading"
              onClose={
                state.status === 'processing' ? onDeleteSegmentation : undefined
              }
            />
          </Margin>
          <Caption style={{ fontStyle: 'italic' }} align="center">
            {helperText}
          </Caption>
          {rejectedRowsWarning}
        </>
      );
    case 'handleFilesError':
    case 'backendError':
      return (
        <>
          {uploadButtonElement}
          <FormErrorMessage
            errorMessage={getCopy('campaigns__upload_segmentation_error')}
          />
        </>
      );
    case 'none':
      return uploadButtonElement;
  }
};
