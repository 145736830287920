import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  ServerError,
  Operation,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { revokeToken, userSession } from '@kivra/identity';
import { captureException } from '@kivra/log';
import { getPageLanguage } from '@kivra/copy-consumer';

const cache = new InMemoryCache();

type Props = {
  children?: React.ReactNode;
  config: {
    sender_portal_api_origin: string;
  };
};

const addHeader = (operation: Operation, key: string, value: string) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      [key]: value,
    },
  }));
  return operation;
};

const setAuthHeader = new ApolloLink((operation, forward) =>
  forward(
    addHeader(operation, 'authorization', `token ${userSession.getToken()}`)
  )
);

const setLanguageHeader = new ApolloLink((operation, forward) =>
  forward(addHeader(operation, 'Accept-Language', getPageLanguage() || 'sv'))
);

const errorHandler = onError(e => {
  const error = new Error(
    JSON.stringify({ location: window?.location?.href, ...e }, undefined, 2)
  );
  error.name = 'SenderPortalGraphqlError';
  captureException(error);
  console.error(error);
  if (errorHasStatusCode(e, 401)) {
    revokeToken().finally(() => {
      window.location.reload();
    });
  }
});

const errorHasStatusCode = (error: ErrorResponse, code: number) => {
  const { networkError, response } = error;
  return (
    (networkError as ServerError)?.statusCode == code ||
    response?.errors?.some((error: unknown) => (error as any)?.status == code)
  );
};

export const SenderPortalApolloProvider = ({ children, config }: Props) => {
  const httpLink = new HttpLink({
    uri: `${config.sender_portal_api_origin}/v1`,
  });

  const client = new ApolloClient({
    cache,
    link: from([setAuthHeader, setLanguageHeader, errorHandler, httpLink]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
