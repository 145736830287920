import { useSenderQuery } from '@apollo-util/generated';
import { gql } from '@apollo/client';
import React, { useEffect } from 'react';
import { useSender } from '../../../../../context/senderContext';
import { useServiceMessage } from '../../../../../context/ServiceMessageProvider';
import { getCopy } from '../../../../../util/copy';
import { getUntypedSenderKey } from '../../../../../util/sender';
import { CampaignField } from './fieldType';
import { SelectField } from './SelectField';

gql`
  query sender($senderKey: ID!) {
    sender(key: $senderKey) {
      campaigns {
        documentTypes {
          key
          label
        }
      }
    }
  }
`;

export const DocumentTypeField: CampaignField = props => {
  const { addServiceMessage } = useServiceMessage();
  const { key } = useSender();
  const { data, loading, error } = useSenderQuery({
    variables: {
      senderKey: getUntypedSenderKey(key),
    },
  });

  useEffect(() => {
    if (
      error ||
      (!loading && data?.sender.campaigns.documentTypes.length === 0)
    ) {
      addServiceMessage({
        title: getCopy('error_generic__title'),
        body: getCopy('campaigns__dialog_error_generic_body'),
        variant: 'error',
      });
    }
  }, [data, loading, error]);

  if (error || loading) {
    return null;
  }

  const documentTypeOptions: {
    label: string;
    value: string;
  }[] =
    data?.sender.campaigns.documentTypes.map(({ key, label }) => ({
      label,
      value: key,
    })) || [];

  return (
    <SelectField {...props} name="documentType" options={documentTypeOptions} />
  );
};
