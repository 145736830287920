import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SelectSender } from '../pages/sender/SelectSender';
import { AccessDeniedSender } from '../pages/accessDeniedSender/AccessDeniedSender';
import { NoMatch } from '../pages/404/NoMatch';
import { SelectOrganization } from '../pages/organization/SelectOrganization';
import { OrganizationOverview } from '../pages/overview/OrganizationOverview';
import { OrganizationsProvider } from '../context/OrganizationsProvider';
import { SendersProvider } from '../context/SendersProvider';
import { SenderRoutes } from './SenderRoutes';

export const PrivateRoutes = () => (
  <OrganizationsProvider>
    <Switch>
      <Route exact path="/select-organization" component={SelectOrganization} />
      <Route path="/organization/:organizationId">
        <SendersProvider>
          <Switch>
            <Route
              path={`/organization/:organizationId/overview/:dashboardType`}
              component={OrganizationOverview}
            />
            <Route
              path="/organization/:organizationId/sender/:senderKey"
              component={SenderRoutes}
            />
            <Route
              exact
              path="/organization/:organizationId/select-sender"
              component={SelectSender}
            />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </SendersProvider>
      </Route>
      <Route
        exact
        path="/access-denied-sender"
        component={AccessDeniedSender}
      />
    </Switch>
  </OrganizationsProvider>
);
