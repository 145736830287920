import {
  Button,
  Caption,
  Margin,
  styled,
  IconButton,
} from '@kivra/react-components';
import React from 'react';
import {
  CloseIcon16,
  ConstructionIcon16,
  LogoutIcon16,
} from '@kivra/react-icons';
import { useRouteMatch } from 'react-router';
import { useAuthenticatedUser } from '../../context/globalContext';
import { logoutUser } from '../../util/authentication';
import { useOrganizationIds } from '../../context/organizationsContext';
import { getRoute, goTo } from '../../routes/history';
import {
  useCurrentOrganization,
  useSenders,
} from '../../context/sendersContext';
import { getCopy } from '../../util/copy';
import { InformationSurface } from '../InformationSurface';
import { Senders } from './Senders';
import { ListItem } from './ListItem';

interface Props {
  topElement: JSX.Element;
  onCloseDrawer: () => void;
  onSenderSelected: (senderKey: string) => void;
}

export const DrawerContent = ({
  onCloseDrawer,
  onSenderSelected,
  topElement,
}: Props) => {
  const { email } = useAuthenticatedUser();
  const { organizationIds } = useOrganizationIds();
  const { isAdminOrganization, organizationId } = useCurrentOrganization();
  const { params, path } = useRouteMatch<{ senderKey?: string }>();
  const senders = useSenders();
  const userHasSeveralOrganizations = organizationIds.length > 1;

  const logoutHandler = async () => {
    logoutUser();
  };

  const activeSenderKey = params.senderKey;
  const isOverview = path.includes('overview');

  const loggedInAsText = `${getCopy('campaigns__logged_in_as')} ${email}`;
  return (
    <div>
      <DrawerHeader>
        {topElement}

        <IconButton
          icon={CloseIcon16}
          iconColor="$text-secondary"
          onClick={onCloseDrawer}
          variant="link"
        />
      </DrawerHeader>
      <Margin all={8}>
        <InformationSurface>
          <Caption align="center">{loggedInAsText}</Caption>
        </InformationSurface>
      </Margin>
      {(senders.length > 1 || isAdminOrganization) && (
        <ListItem
          onClick={
            isOverview
              ? undefined
              : () => goTo(getRoute({ id: 'ongoing', organizationId }))
          }
          isActive={isOverview}
        >
          {getCopy('campaigns__overview_all_tenants')}
        </ListItem>
      )}
      {!isAdminOrganization && (
        <SenderContainer>
          <Senders
            type="tenant"
            onSenderSelected={onSenderSelected}
            activeSenderKey={activeSenderKey}
          />
          <Senders
            type="store"
            onSenderSelected={onSenderSelected}
            activeSenderKey={activeSenderKey}
          />
        </SenderContainer>
      )}
      <LogoutContainer>
        {userHasSeveralOrganizations && (
          <Button
            variant="link"
            onClick={() => goTo(getRoute({ id: 'select-organization' }))}
            size="small"
            icon={() => <ConstructionIcon16 color="currentColor" />}
          >
            {getCopy('campaigns__change_organization')}
          </Button>
        )}
        <Button
          variant="link"
          onClick={logoutHandler}
          size="small"
          icon={() => <LogoutIcon16 color="currentColor" />}
        >
          {getCopy('misc_option__logout')}
        </Button>
      </LogoutContainer>
    </div>
  );
};

const DrawerHeader = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid $border-distinct`,
  display: 'flex',
  height: '$responsive-topbar-height',
  justifyContent: 'space-between',
  overflow: 'hidden',
  padding: [0, '$spacing-16'],
});

const LogoutContainer = styled.div({
  backgroundColor: '$background-secondary',
  borderTop: `1px solid $border-distinct`,
  bottom: 0,
  boxShadow: '0px 0 10px rgba(255, 255, 255, 0.8)',
  display: 'flex',
  justifyContent: 'space-evenly',
  overflow: 'visible',
  paddingBottom: '$spacing-8',
  paddingTop: '$spacing-8',
  position: 'fixed',
  width: '100%',
});

const SenderContainer = styled.div({
  overflowY: 'auto',
});
