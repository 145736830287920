import { createContext, useContext } from 'react';
import { userSession, isAuthenticated } from '@kivra/identity';
import {
  decodeIdToken,
  HeimdallQueryParameters,
  PersonaIdToken,
} from '@kivra/authentication';
import { Config } from '../types/config';
import { UserProfile } from '../types/userProfile';
import { renewAccessToken } from '../util/handleUnauthorizedErrorWrapper';

export interface GlobalContext {
  config: Config;
  global: Window;
  heimdallParameters: HeimdallQueryParameters;
}

export const GlobalContext = createContext<GlobalContext>(null as any);

const {
  Consumer: GlobalContextConsumer,
  Provider: GlobalContextProvider,
} = GlobalContext;

const hookCreator = <T>(map: (gc: GlobalContext) => T) => (): T => {
  const global = useContext(GlobalContext);
  return map(global);
};

export const useAuthenticatedUser = hookCreator((g) => getUserState(g));
export const useConfig = hookCreator((g) => g.config);
export const useStaticOrigin = hookCreator((g) => g.config.kivra_static_origin);
export const useGlobal = hookCreator((g) => g.global);
export const useHeimdallParameters = hookCreator((g) => g.heimdallParameters);
export { GlobalContextProvider, GlobalContextConsumer };

const getUserState = (g: GlobalContext): UserProfile => {
  if (!isAuthenticated()) {
    renewAccessToken();
    return {
      email: '',
      isAdmin: false,
      name: '',
    };
  }

  const user = decodeIdToken<PersonaIdToken>(
    userSession.getSession()?.userId || ''
  );

  if (!user) {
    throw new Error('Could not get user info');
  }

  const isAdmin =
    user.organizations?.includes(
      g.config.sender_backoffice_admin_organization_id
    ) || false;

  return {
    email: user.email,
    name: user.name,
    isAdmin,
  };
};
