import { ApiResponseError } from '@kivra/common';
import { ServiceState } from '../types/serviceState';

export const loadingServiceState = {
  response: undefined,
  error: undefined,
  loading: true,
};

export const initialServiceState = {
  response: undefined,
  error: undefined,
  loading: false,
};

export const createErrorServiceState = (error: ApiResponseError) => ({
  error,
  response: undefined,
  loading: false,
});

export const createSuccessServiceState = <T>(response: T): ServiceState<T> => {
  return {
    response,
    error: undefined,
    loading: false,
  };
};
