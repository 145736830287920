import { revokeToken, userSession } from '@kivra/identity';
import { getOnLoginRoute, getRoute, goTo } from '../routes/history';
import { senderStorage } from './senderStorage';

/**
 * When login is successful the user will be redirected to a correct page.
 * First check if there is a
 */
export const onLoginSuccess = async () => {
  const invalidRoutes = ['/', '/login'];
  const { sender, organizationId } = senderStorage.get() || {};
  const onLoginRoute = getOnLoginRoute();
  if (onLoginRoute && invalidRoutes.indexOf(onLoginRoute) === -1) {
    goTo(onLoginRoute, false);
  } else if (sender && organizationId) {
    goTo(
      getRoute({
        id: 'ongoing-and-upcoming',
        organizationId,
        senderKey: sender,
      })
    );
  } else {
    goTo(getRoute({ id: 'select-organization' }));
  }
};

export const logoutUser = (options?: {
  shouldNavigate?: boolean;
  shouldClearTenantStorage?: boolean;
}) => {
  const { shouldNavigate = true, shouldClearTenantStorage = true } =
    options || {};
  userSession.deleteSession();
  revokeToken();
  if (shouldClearTenantStorage) senderStorage.delete();
  if (shouldNavigate) location.reload();
};
