import type { CampaignRequest } from '@sdk/campaigns';
import { omit } from '@kivra/common';
import { FormState } from 'react-hook-form';
import {
  CampaignForm,
  CampaignFormKey,
  CampaignFormErrors,
} from '../../../../../types/campaignForm';
import { getDateString } from '../../../../../util/dates';
import { destinationUrlDefaultValue } from './createCampaignForm';

export const getDirtyFields = (
  formState: FormState<CampaignForm>,
  formValues: CampaignForm
): Partial<CampaignForm> => {
  return getDirtyKeys(formState).reduce(
    (values, field) => ({ ...values, [field]: formValues[field] }),
    {}
  );
};

export const createCampaignRequest = (
  dirtyFields: Partial<CampaignForm>
): CampaignRequest => {
  const campaignRequest: Partial<CampaignRequest> = {};
  const { destinationUrl, dates, image, ...campaignValues } = omit(
    dirtyFields,
    ['submitActionType', 'isSegmentationJobOngoing']
  );
  if (destinationUrl === '' || destinationUrl === destinationUrlDefaultValue) {
    campaignRequest.destinationUrl = null;
  } else {
    campaignRequest.destinationUrl = destinationUrl;
  }
  if (dates?.activeFrom) {
    campaignRequest.activeFrom = getDateString(dates.activeFrom);
  }
  if (dates?.activeTo) {
    campaignRequest.activeTo = getDateString(dates.activeTo);
  }
  if (dates?.attachFrom) {
    campaignRequest.attachFrom = getDateString(dates.attachFrom);
  }
  if (dates?.attachTo) {
    campaignRequest.attachTo = getDateString(dates.attachTo);
  }
  if (image) {
    campaignRequest.image = image;
  }
  return { ...campaignValues, ...campaignRequest };
};

export const areErrorsAcceptableInDraft = (errors: CampaignFormErrors) => {
  const acceptedErrors: string[] = ['required', 'segmentationStatusIsOngoing'];
  return Object.values(errors).every(({ type }) =>
    acceptedErrors.includes(type)
  );
};

export const isReceipt = (documentType: string | null) =>
  documentType === 'receipt';

const getDirtyKeys = (
  formState: FormState<CampaignForm>
): CampaignFormKey[] => {
  const changedFields = Object.keys(formState.dirtyFields) as CampaignFormKey[];
  return changedFields.filter(
    key => !['isSegmentationJobOngoing', 'submitActionType'].includes(key)
  );
};

export const isFormDirty = (formState: FormState<CampaignForm>) =>
  getDirtyKeys(formState).length > 0;
