import React from 'react';
import { NavDrawerMenu, useIsMediumScreenSize } from '@kivra/react-components';
import {
  PreviousIcon,
  SignIcon,
  OngoingIcon,
  CalendarIcon,
} from '@kivra/react-icons';
import { Route } from 'react-router';
import { getRoute, goTo } from '../../../routes/history';
import { CampaignListType } from '../../../types/campaignList';
import { useCurrentOrganization } from '../../../context/sendersContext';
import { getCopy } from '../../../util/copy';
import { CampaignsNavDrawer } from '../../../components/CampaignsNavDrawer';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedMenuType: CampaignListType;
}

export const OverviewCampaignsDrawer = ({
  open,
  onClose,
  selectedMenuType,
}: Props) => {
  const { organizationId } = useCurrentOrganization();

  const isMediumSize = useIsMediumScreenSize();
  const onClick = (id: string) => {
    goTo(
      getRoute({ id, organizationId } as Extract<
        Route,
        { id: CampaignListType }
      >)
    );
  };
  const campaignMenuItems = [
    {
      id: 'ongoing',
      iconComponent: OngoingIcon,
      label: getCopy('campaigns__status__ongoing'),
    },
    {
      id: 'upcoming',
      iconComponent: CalendarIcon,
      label: getCopy('campaigns__status__upcoming'),
    },
    {
      id: 'draft',
      iconComponent: SignIcon,
      label: getCopy('campaigns__status__draft'),
    },
    {
      id: 'previous',
      iconComponent: PreviousIcon,
      label: getCopy('campaigns__status__previous'),
    },
  ];

  return (
    <CampaignsNavDrawer
      variant={isMediumSize ? 'temporary' : 'permanent'}
      open={open}
      onClose={onClose}
    >
      <NavDrawerMenu
        onClick={onClick}
        selectedId={selectedMenuType}
        menuItems={campaignMenuItems}
      />
    </CampaignsNavDrawer>
  );
};
