import React, { useEffect } from 'react';
import {
  ServiceMessage,
  AnimatedServiceMessage,
  Body,
} from '@kivra/react-components';

import { PreviewFeedbackState } from '../../../../types/previewFeedbackType';
import {
  getServiceErrorCopy,
  previewFeedbackTitleCopy,
} from '../../../../util/copyKeys';
import { getCopy } from '../../../../util/copy';
import { CampaignFormErrors } from '../../../../types/campaignForm';

interface Props {
  state: PreviewFeedbackState;
  onClose: (previewType?: PreviewFeedbackState) => void;
  formErrors: CampaignFormErrors;
}

export const PreviewFeedback = ({ state, onClose, formErrors }: Props) => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (state?.type === 'serviceSuccess') {
      timeout = setTimeout(() => {
        onClose();
      }, 3000);
    } else if (
      state?.type === 'publishError' &&
      Object.keys(formErrors).length === 0
    ) {
      onClose();
    }
    return () => clearTimeout(timeout);
  }, [state, formErrors, onClose]);

  const getMessage = () => {
    if (state?.type === 'serviceError') {
      return getCopy(getServiceErrorCopy(state.error));
    } else if (
      state?.type === 'publishError' &&
      Object.values(formErrors).length > 0
    ) {
      return <ErrorMessages errors={formErrors as CampaignFormErrors} />;
    }
  };

  return (
    <AnimatedServiceMessage marginTop={0}>
      <ServiceMessage
        dataTestId="PreviewFeedback"
        fullWidth={false}
        variant={state.type === 'serviceSuccess' ? 'success' : 'error'}
        title={getCopy(previewFeedbackTitleCopy[state.type])}
        onCloseRequest={() => onClose()}
      >
        {getMessage()}
      </ServiceMessage>
    </AnimatedServiceMessage>
  );
};

const ErrorMessages = ({ errors }: { errors: CampaignFormErrors }) => {
  const errorTexts = [];
  if (errors?.image?.type === 'required') {
    errorTexts.push(getCopy('campaigns__validation_error__image'));
  }
  if (
    errors?.dates?.type === 'required' &&
    errors?.dates?.message?.includes('attach')
  ) {
    errorTexts.push(getCopy('campaigns__validation_error__attach_date'));
  }
  if (
    errors?.dates?.type === 'required' &&
    errors?.dates?.message?.includes('active')
  ) {
    errorTexts.push(getCopy('campaigns__validation_error__active_date'));
  }
  if (
    errors?.isSegmentationJobOngoing?.type === 'segmentationStatusIsOngoing'
  ) {
    errorTexts.push(
      getCopy('campaigns__publish_while_segmentation_job_in_progress')
    );
  }
  if (errorTexts.length === 0) {
    errorTexts.push(getCopy('campaigns__editor_form_error'));
  }
  return (
    <ul>
      {errorTexts.map((text) => {
        return (
          <li key={text}>
            <Body size="small" color="inherit">
              {text}
            </Body>
          </li>
        );
      })}
    </ul>
  );
};
