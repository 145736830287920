import React, { useEffect, useState } from 'react';
import type { ClientStatusType } from '@sdk/campaigns';
import { SearchBar, styled } from '@kivra/react-components';
import { CampaignsContextProvider } from '../../../context/campaignsContext';
import { useCurrentOrganization } from '../../../context/sendersContext';
import { campaignServiceStates } from '../../../util/useCampaignsService';
import { CampaignsBlock } from '../../../components/CampaignsBlock';
import { NoCampaigns } from '../../../components/NoCampaigns';
import { getCopy } from '../../../util/copy';
import { OrganizationCampaignsTable } from './OrganizationCampaignsTable';

interface Props {
  types: ClientStatusType[];
}

const SearchBarWrapper = styled('div')({
  width: 275,
});

export const OrganizationCampaigns = ({ types }: Props) => {
  const { organizationId, isAdminOrganization } = useCurrentOrganization();
  const [searchString, setSearchString] = useState('');
  const serviceStates = campaignServiceStates({
    organizationId,
    types,
    isAdmin: isAdminOrganization,
  });

  const fetchCampaigns = () => {
    serviceStates.forEach(state => {
      state.callService();
    });
  };

  useEffect(() => {
    fetchCampaigns();
  }, [organizationId]);

  return (
    <CampaignsContextProvider value={{ reloadCampaigns: fetchCampaigns }}>
      {serviceStates.map(({ serviceState, type }) => (
        <CampaignsBlock
          key={type}
          type={type}
          serviceState={serviceState}
          actionBar={
            <SearchBarWrapper>
              <SearchBar
                clearIconText="clear"
                value={searchString}
                placeholder={getCopy('campaigns__search_campaign_placeholder')}
                onChange={text => {
                  setSearchString(text.toLowerCase());
                }}
                size="small"
              />
            </SearchBarWrapper>
          }
        >
          {serviceState.response?.length ? (
            <OrganizationCampaignsTable
              filter={searchString}
              campaigns={serviceState.response}
            />
          ) : (
            <NoCampaigns type={type} />
          )}
        </CampaignsBlock>
      ))}
    </CampaignsContextProvider>
  );
};
