import React, { useState } from 'react';
import { useOnMount } from '@kivra/react-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { captureException } from '@kivra/log';
import type { Campaign } from '@sdk/campaigns';
import { SenderType } from '@kivra/types/sender';
import { CampaignForm, CampaignFormErrors } from '../../types/campaignForm';
import { useCampaign, useCampaignStatus } from '../../context/campaignContext';
import { useSender } from '../../context/senderContext';
import { useConfig } from '../../context/globalContext';
import { Content } from '../../components/campaignLayout/Content';
import { Main } from '../../components/campaignLayout/Main';
import { SideBar } from '../../components/campaignLayout/SideBar';
import { Preview } from '../../components/preview';
import { PreviewFeedbackState } from '../../types/previewFeedbackType';
import { isReceiptSender } from '../../util/senderType';
import { Editor } from './components/editor/Editor';
import { EditorTopBar } from './components/editor/EditorTopBar';
import { PublishDialog } from './components/editor/PublishDialog';
import {
  areErrorsAcceptableInDraft,
  createCampaignRequest,
  getDirtyFields,
} from './components/editor/utils/campaignEditor';
import { SegmentationUploadContainer } from './components/editor/segmentation/SegmentationUploadContainer';
import { PreviewFeedback } from './components/editor/PreviewFeedback';
import { EditInformation } from './components/editor/EditInformation';
import { createCampaignForm } from './components/editor/utils/createCampaignForm';
import {
  isCampaignPublishable,
  PublishableCampaign,
} from './components/editor/utils/publishableCampaign';

export const CampaignEditor = () => {
  const { kivra_campaigns_hide_segmentation: hideSegmentation } = useConfig();
  const { type: senderType } = useSender();
  const { saveCampaign, campaign } = useCampaign(false);
  const campaignStatus = useCampaignStatus();

  const form = useForm<CampaignForm>({
    mode: 'onTouched',
    defaultValues: createCampaignForm(senderType, campaign),
    shouldUnregister: false,
  });

  useOnMount(form.trigger);

  const [campaignToPublish, setCampaignToPublish] =
    useState<PublishableCampaign | null>(null);
  const [previewFeedback, setPreviewFeedback] = useState<
    PreviewFeedbackState | undefined
  >();

  const save = async (formValues: CampaignForm): Promise<Campaign> => {
    const dirtyFields = getDirtyFields(form.formState, formValues);
    const campaignRequest = createCampaignRequest(dirtyFields);
    return saveCampaign({ ...campaignRequest }).then(campaign => {
      form.reset(formValues, { keepDirty: false, keepErrors: true });
      return campaign;
    });
  };

  const initPublishFlow = () => {
    const publishCandidate = {
      ...createCampaignRequest(form.getValues()),
    };
    if (isCampaignPublishable(publishCandidate)) {
      setCampaignToPublish(publishCandidate);
    } else {
      setPreviewFeedback({ type: 'publishError' });
    }
  };

  const saveOrPublish = async (formValues: CampaignForm): Promise<void> => {
    if (formValues.submitActionType === 'publish') {
      initPublishFlow();
    } else {
      save(formValues)
        .then(() => {
          setPreviewFeedback({ type: 'serviceSuccess' });
        })
        .catch(error => {
          captureException(error);
          setPreviewFeedback({ type: 'serviceError', error });
          return Promise.reject();
        });
    }
  };

  const onSubmit = form.handleSubmit(saveOrPublish, errors => {
    const formValues = form.getValues();
    if (
      areErrorsAcceptableInDraft(errors as CampaignFormErrors) &&
      formValues.submitActionType === 'save'
    ) {
      saveOrPublish(formValues);
    } else {
      setPreviewFeedback({ type: 'publishError' });
    }
  });

  const submitHandler = async () => {
    setPreviewFeedback(undefined);
    await form.trigger('submitActionType');
    onSubmit();
  };

  const previewFeedbackElement = previewFeedback ? (
    <PreviewFeedback
      state={previewFeedback}
      onClose={() => setPreviewFeedback(undefined)}
      formErrors={form.formState.errors as CampaignFormErrors}
    />
  ) : null;

  return (
    <FormProvider {...form}>
      <EditorTopBar submitHandler={submitHandler} />

      <Main>
        <SideBar>
          <EditInformation />
          <Editor senderType={senderType} campaignStatus={campaignStatus} />
          {isSegmentationEnabled(hideSegmentation, senderType) && (
            <Controller
              control={form.control}
              name="isSegmentationJobOngoing"
              rules={{
                validate: {
                  segmentationStatusIsOngoing: (value: boolean) => !value,
                },
              }}
              render={props => {
                return (
                  <SegmentationUploadContainer
                    onChange={props.field.onChange}
                    createCampaign={() =>
                      saveCampaign()
                        .then(campaign => {
                          setPreviewFeedback({ type: 'serviceSuccess' });
                          return campaign;
                        })
                        .catch(error => {
                          setPreviewFeedback({ type: 'serviceError', error });
                          captureException(error);
                          throw error;
                        })
                    }
                  />
                );
              }}
            />
          )}
        </SideBar>
        <Content>
          <Preview
            previewFeedbackElement={previewFeedbackElement}
            campaign={form.getValues()}
          />
        </Content>
      </Main>

      {campaignToPublish && (
        <PublishDialog
          save={() => save(form.getValues())}
          onClose={() => setCampaignToPublish(null)}
          campaign={campaignToPublish}
        />
      )}
    </FormProvider>
  );
};

function isSegmentationEnabled(
  hideSegmentation: boolean,
  senderType: SenderType
) {
  return !hideSegmentation && !isReceiptSender(senderType);
}
