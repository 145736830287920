import { createBrowserHistory } from 'history';

export interface HistoryState {
  previousRoute?: string;
  onLoginRoute?: string;
}

export type CampaignPageType = 'edit' | 'details';

export const getOnLoginRoute = () => {
  return (
    history.location.state &&
    history.location.state.onLoginRoute &&
    history.location.state.onLoginRoute
  );
};

export const setOnLoginRoute = (route: string) => {
  history.location.state = history.location.state ?? {};
  history.location.state.onLoginRoute = route;
};

export const getPreviousRoute = () => {
  return (
    history.location.state &&
    history.location.state.previousRoute &&
    history.location.state.previousRoute
  );
};

export const goTo = (path: string, withHistory = true) => {
  history.push(withHistory ? createNewInternalRoute(path) : path);
};

export const goBack = (fallbackRoute: string) => {
  const previousRoute = getPreviousRoute();
  if (previousRoute) {
    history.goBack();
  } else {
    goTo(fallbackRoute);
  }
};

export const getDefaultPrivateRoute = (
  organizationId: string,
  senderKey: string
) =>
  getRoute({
    organizationId,
    senderKey: senderKey,
    id: 'ongoing-and-upcoming',
  });

const createNewInternalRoute = (pathname: string) => {
  return {
    pathname,
    state: {
      previousRoute: history.location.pathname,
    },
  };
};

export const history = createBrowserHistory<HistoryState>();

export function getRoute(route: Route): string {
  switch (route.id) {
    case 'draft':
      return `/organization/${route.organizationId}/${senderOrOverview(
        route.senderKey
      )}/draft`;
    case 'previous':
      return `/organization/${route.organizationId}/${senderOrOverview(
        route.senderKey
      )}/previous`;
    case 'ongoing':
      return `/organization/${route.organizationId}/overview/ongoing`;
    case 'upcoming':
      return `/organization/${route.organizationId}/overview/upcoming`;
    case 'ongoing-and-upcoming':
      return `/organization/${route.organizationId}/sender/${route.senderKey}/ongoing-and-upcoming`;
    case 'campaign':
      return `/organization/${route.organizationId}/sender/${route.senderKey}/campaign/${route.campaignId}/${route.pageType}`;
    case 'new-campaign':
      return `/organization/${route.organizationId}/sender/${route.senderKey}/campaign/new/edit`;
    case 'select-sender':
      return `/organization/${route.organizationId}/select-sender`;
    case 'select-organization':
      return `/select-organization`;
    case 'login':
    default:
      return `/login`;
  }
}

const senderOrOverview = (senderKey?: string) =>
  senderKey ? `sender/${senderKey}` : 'overview';

type Route =
  | {
      id: 'campaign';
      organizationId: string;
      senderKey: string;
      campaignId: string;
      pageType: CampaignPageType;
    }
  | {
      id: 'draft';
      organizationId: string;
      senderKey: string;
    }
  | {
      id: 'draft';
      organizationId: string;
      senderKey?: undefined;
    }
  | {
      id: 'previous';
      organizationId: string;
      senderKey: string;
    }
  | {
      id: 'previous';
      organizationId: string;
      senderKey?: undefined;
    }
  | {
      id: 'ongoing';
      organizationId: string;
    }
  | {
      id: 'upcoming';
      organizationId: string;
    }
  | {
      id: 'ongoing-and-upcoming';
      organizationId: string;
      senderKey: string;
    }
  | {
      id: 'new-campaign';
      organizationId: string;
      senderKey: string;
    }
  | {
      id: 'select-sender';
      organizationId: string;
    }
  | {
      id: 'select-organization';
    }
  | {
      id: 'login';
    };

/**
 * DistributiveOmit is a Omit function för union types.
 * Regular Omit will only operate on the intersection of a union type,
 * while DistributiveOmit operates on the entire union. That is to say that
 * the desired keys will be omitted from every possible varation within the
 * union. The key to this is `Type extends any ? ... : ...` which
 * acts as a sort of iterator on the union type. Read more at:
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#distributive-conditional-types
 */
type DistributiveOmit<Type, Keys extends keyof any> = Type extends any
  ? Omit<Type, Keys>
  : never;

/**
 *
 * @param base A subset of route parameters to be predefined. The resulting function will accept remaing parameters for any routes that require the base parameters.
 */
export const createGetRoute = <
  BaseParams extends DistributiveOmit<Route, 'id'>
>(
  base?: BaseParams
) => (
  rest: DistributiveOmit<Extract<Route, BaseParams>, keyof BaseParams> & {
    id: string;
  }
) => getRoute({ ...base, ...rest } as Route);
