import { css, Flex, StyleException } from '@kivra/react-components';
import React from 'react';
import {
  useCalcPixels,
  useCalcPixelsString,
  usePreviewCampaign,
} from '../../../context/previewContext';
import {
  DOCUMENT_WIDTH_DESKTOP,
  DOCUMENT_WRAPPER_WIDTH_DESKTOP,
} from '../utils/constants';
import { CampaignImage } from '../CampaignImage';
import { Document } from '../Document';
import { LayoutBase } from './../utilComponents';
import { ActionCard } from './ActionCard';
import { LetterCard } from './LetterCard';

export const DesktopLayout = () => {
  const { position } = usePreviewCampaign();
  const calcPixels = useCalcPixels();
  const calcPixelsString = useCalcPixelsString();
  const fallbackImagePath =
    '/assets/illustrations/campaigns/preview-fallback.svg';

  return (
    <LayoutBase
      className={css({
        backgroundColor: '$background-primary',
        padding: StyleException(calcPixelsString(4)),
        display: 'grid',
        gridTemplateColumns: `${calcPixelsString(
          DOCUMENT_WRAPPER_WIDTH_DESKTOP
        )} auto`,
        justifyContent: 'center',
      })}
    >
      <div style={{ padding: calcPixelsString(4) }}>
        <Document documentWidth={calcPixels(DOCUMENT_WIDTH_DESKTOP)} />
      </div>
      <Flex
        direction="column"
        className={css({
          padding: StyleException(calcPixelsString(4)),
          '> * ': {
            marginBottom: StyleException(calcPixelsString(8)),
          },
        })}
      >
        <LetterCard />
        {position === 'top' && (
          <CampaignImage fallbackImagePath={fallbackImagePath} />
        )}
        <ActionCard />
        {position === 'bottom' && (
          <CampaignImage fallbackImagePath={fallbackImagePath} />
        )}
      </Flex>
    </LayoutBase>
  );
};
