import type { PositionType, ClientStatusType } from '@sdk/campaigns';
import { SenderType } from '@kivra/types/sender';
import { ApiResponseError } from '@kivra/common';
import { PreviewFeedbackState } from '../types/previewFeedbackType';
import { ImageError } from '../types/editorValidationErrors';
import { CampaignTableColumns } from '../types/table';
import { CopyKeysWithoutArgs } from '../types/copyKeys';
import {
  maxWeightInKB,
  minWidth,
} from '../pages/editor/components/editor/utils/uploadInput';
import { SegmentationUploadState } from '../pages/editor/components/editor/segmentation/SegmentationUploadContainer';
import { getCopy } from './copy';

export const tablePositionCopy: Record<PositionType, CopyKeysWithoutArgs> = {
  bottom: 'campaigns__position__bottom',
  top: 'campaigns__position__top',
};

export const getDocumentTypeText = (documentType: string) => {
  switch (documentType) {
    case 'all':
      return getCopy('sender_portal_api__document_type_all');
    case 'invoice':
      return getCopy('sender_portal_api__document_type_invoice');
    case 'receipt':
      return getCopy('sender_portal_api__document_type_receipt');
    case 'letter':
      return getCopy('sender_portal_api__document_type_letter');
    case 'letter.salary':
      return getCopy('sender_portal_api__document_type_letter.salary');
    case 'letter.creditnotice':
      return getCopy('sender_portal_api__document_type_letter.creditnotice');
    case 'letter.government':
      return getCopy('sender_portal_api__document_type_letter.government');
    case 'invoice.reminder':
      return getCopy('sender_portal_api__document_type_invoice.reminder');
    case 'invoice.renewal':
      return getCopy('sender_portal_api__document_type_invoice.renewal');
    case 'invoice.renewal':
      return getCopy('sender_portal_api__document_type_invoice.renewal');
    case 'booking':
      return getCopy('sender_portal_api__document_type_booking');
    default:
      return '-';
  }
};

export const imageErrorMessages = (imageError: ImageError) => {
  const errors = {
    aspect_ratio: getCopy('campaigns__image_aspect_ratio_error'),
    min_width: getCopy('misc__image_min_width_error', {
      width: minWidth + '',
    }),
    size_to_big: getCopy('misc__image_maxsize_error', {
      size: maxWeightInKB + '',
    }),
    unknown: getCopy('misc__image_unknown_error'),
  };
  return errors[imageError];
};

export const getHeaderLabel = (column: CampaignTableColumns) => {
  switch (column) {
    case 'title':
      return getCopy('campaigns__campaign');
    case 'updatedAt':
      return getCopy('campaigns__updated_at');
    case 'timePeriod':
      return getCopy('campaigns__time_period');
    case 'position':
      return getCopy('campaigns__position');
    case 'documentType':
      return getCopy('campaigns__document_type');
    case 'segmentation':
      return getCopy('campaigns__segmentation');
    case 'publishedAt':
      return getCopy('campaigns__published_at');
    case 'info':
      return getCopy('campaigns__info');
  }
};

export const campaignStatusTexts: Record<
  ClientStatusType,
  CopyKeysWithoutArgs
> = {
  draft: 'campaigns__status__draft',
  ongoing: 'campaigns__status__ongoing',
  upcoming: 'campaigns__status__upcoming',
  previous: 'campaigns__status__previous',
};

export const statisticStatusCopy: Record<
  'previous' | 'ongoing',
  CopyKeysWithoutArgs
> = {
  previous: 'campaigns__status__previous_singular',
  ongoing: 'campaigns__status__ongoing',
};

export const previewFeedbackTitleCopy: Record<
  PreviewFeedbackState['type'],
  CopyKeysWithoutArgs
> = {
  serviceError: 'campaigns__save_campaign_error_title',
  serviceSuccess: 'campaigns__save_campaign_success_title',
  publishError: 'campaigns__publish_error_header',
};

export const getStatusAreaTexts = (
  state: Extract<
    SegmentationUploadState,
    { status: 'processing' | 'preparingRequest' | 'uploading' }
  >
): { label: string; helperText: string } => {
  switch (state.status) {
    case 'preparingRequest':
      return {
        label: getCopy('campaigns__preparing_file'),
        helperText: getCopy('campaigns__uploading_file_help'),
      };
    case 'processing':
      return {
        label: `${getCopy('campaigns__processing_file')} (${Math.floor(
          state.jobStatus.progressPermille / 10
        )}%)`,
        helperText: getCopy('campaigns__processing_file_help'),
      };
    case 'uploading':
      return {
        label: getCopy('campaigns__uploading_file'),
        helperText: getCopy('campaigns__uploading_file_help'),
      };
  }
};

export const senderContentTypeCopy: Record<SenderType, CopyKeysWithoutArgs> = {
  chain: 'campaigns__sender_content_type_receipts',
  store: 'campaigns__sender_content_type_receipts',
  tenant: 'campaigns__sender_content_type_mail',
};

export const sendersByTypeCopy: Record<SenderType, CopyKeysWithoutArgs> = {
  chain: 'campaigns__receipt_senders',
  store: 'campaigns__receipt_senders',
  tenant: 'campaigns__postal_senders',
};

export const pluralDocumentTypeCopy = (documentType: string): string => {
  switch (documentType) {
    case 'receipt':
      return getCopy('campaigns__document_type__receipt', 2);
    default:
      return getCopy('campaigns__document', 2);
  }
};

export const getServiceErrorCopy = (error: ApiResponseError) => {
  if (
    ApiResponseError.isCampaignImageBase64Error(error) ||
    ApiResponseError.isCampaignImagePngError(error)
  ) {
    return 'campaigns__save_campaign_image_error';
  } else if (ApiResponseError.isCampaignImageJpegError(error)) {
    return 'campaigns__save_campaign_jpeg_image_error';
  } else {
    return 'campaigns__dialog_error_generic_body';
  }
};
