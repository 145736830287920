import {
  Heading,
  Loader,
  ServiceMessage,
  Margin,
  Flex,
} from '@kivra/react-components';
import React from 'react';
import type { Campaign, ClientStatusType } from '@sdk/campaigns';
import { State as ServiceState } from '../util/useServiceCaller';
import { campaignStatusTexts } from '../util/copyKeys';
import { getCopy } from '../util/copy';

interface Props {
  serviceState: ServiceState<Campaign[]>;
  type: ClientStatusType;
  actionBar?: JSX.Element;
  children?: React.ReactNode;
}

export const CampaignsBlock = ({
  serviceState,
  type,
  children,
  actionBar,
}: Props) => {
  const loadingElement = serviceState.status === 'loading' && (
    <Margin all={16}>
      <Loader size={40} />
    </Margin>
  );
  const errorElement = serviceState.status === 'error' && (
    <ServiceMessage
      variant="error"
      title={getCopy('campaigns__get_campaigns_error_title')}
    >
      {getCopy('campaigns__dialog_error_generic_body')}
    </ServiceMessage>
  );
  const successElement = serviceState.status === 'success' && children;
  const campaignCount =
    serviceState.status === 'success' && serviceState.response.length
      ? `(${serviceState.response.length})`
      : '';
  return (
    <>
      <Margin top={24} bottom={8}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Heading size="large">
            {`${getCopy(campaignStatusTexts[type])} ${campaignCount}`}
          </Heading>
          {actionBar && Boolean(campaignCount) && actionBar}
        </Flex>
      </Margin>

      {loadingElement}
      {errorElement}
      {successElement}
    </>
  );
};
