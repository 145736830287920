import { Heading, KivraTheme, Radio, styled } from '@kivra/react-components';
import React from 'react';

interface Props {
  zoomRatio: number;
  setZoomRatio: (zoomRatio: number) => void;
}

const marks = [
  {
    value: '0.75',
    label: '75%',
  },
  {
    value: '1',
    label: '100%',
  },
  {
    value: '1.25',
    label: '125%',
  },
];

export const Zoom = ({ zoomRatio, setZoomRatio }: Props) => {
  return (
    <KivraTheme colorScheme="dark">
      <Root>
        <Heading size="xsmall">Zoom</Heading>
        <Radio.Group
          name="number"
          value={zoomRatio.toString()}
          onChange={numberString => {
            setZoomRatio(parseFloat(numberString));
          }}
        >
          {marks.map(mark => (
            <Radio.Button
              key={mark.value}
              value={mark.value}
              label={mark.label}
            />
          ))}
        </Radio.Group>
      </Root>
    </KivraTheme>
  );
};

const Root = styled('div')({
  position: 'fixed',
  right: '$spacing-16',
  bottom: '$spacing-16',
  zIndex: 100,
  background: '$gray100',
  padding: ['$spacing-20', '$spacing-24'],
  borderRadius: '$radius-medium',
});
