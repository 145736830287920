import {
  CampaignForm,
  CampaignFormKey,
} from '../../../../../types/campaignForm';
import { getCopy } from '../../../../../util/copy';
import { pluralDocumentTypeCopy } from '../../../../../util/copyKeys';
import { BlockProps } from '../Block';
import { isReceipt } from './campaignEditor';

type CampaignBlockNames =
  | Exclude<
      CampaignFormKey,
      | 'bannerConfig'
      | 'dates'
      | 'isSegmentationJobOngoing'
      | 'submitActionType'
      | 'useSso'
    >
  | 'active'
  | 'attach';

export const getBlockProps = (
  name: CampaignBlockNames,
  form: CampaignForm
): BlockProps => {
  const isReceiptType = isReceipt(form.documentType);
  const documentTypeText = pluralDocumentTypeCopy(
    form.documentType
  ).toLowerCase();

  const blockPropsByFieldName: Record<CampaignBlockNames, BlockProps> = {
    title: {
      label: getCopy('campaigns__title'),
      optional: true,
    },
    documentType: {
      label: getCopy('campaigns__document_type'),
      tooltipText: getCopy('campaigns__document_type_tooltip'),
    },
    position: {
      label: getCopy('campaigns__position'),
      tooltipText: getCopy('campaigns__position_tooltip'),
    },
    image: {
      label: getCopy('campaigns__upload_content'),
      tooltipText: getCopy(
        isReceiptType
          ? 'campaigns__image_tooltip_receipt'
          : 'campaigns__image_tooltip_tenant'
      ),
    },
    active: {
      label: getCopy('campaigns__active_label'),
      tooltipText: `${getCopy('campaigns__datepicker_tooltip', {
        documentType: documentTypeText,
      })}\n\n${getCopy('campaigns__date_range_times_tooltip')}`,
    },
    attach: {
      label: getCopy('campaigns__attach_label', {
        documentType: documentTypeText,
      }),
      tooltipText: getCopy('campaigns__date_range_times_tooltip'),
    },
    destinationUrl: {
      label: getCopy('campaigns__link'),
      optional: true,
    },
    targetGroup: {
      label: getCopy('campaigns__target_group'),
    },
  };

  return blockPropsByFieldName[name];
};
