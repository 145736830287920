import { ColorPicker, FieldSet } from '@kivra/react-components';
import React from 'react';
import { getCopy } from '../../../util/copy';

type Props = {
  color: string;
  onChange: (color: string) => void;
};

export const BannerBackground = ({ color, onChange }: Props) => (
  <FieldSet
    title={getCopy('campaigns__banner_editor_background')}
    endAdornment={<ColorPicker color={color} onChange={onChange} />}
  ></FieldSet>
);
