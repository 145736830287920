import { createContext, useContext } from 'react';

export interface CampaignsContext {
  reloadCampaigns: () => void;
}

export const CampaignsContext = createContext<CampaignsContext>(null as any);

const {
  Consumer: CampaignsContextConsumer,
  Provider: CampaignsContextProvider,
} = CampaignsContext;

const hookCreator = <T>(map: (c: CampaignsContext) => T) => (): T => {
  const campaigns = useContext(CampaignsContext);
  return map(campaigns);
};

export const useReloadCampaigns = hookCreator((c) => c.reloadCampaigns);
export { CampaignsContextProvider, CampaignsContextConsumer };
