import {
  Flex,
  FocusWrapper,
  Margin,
  NavDrawer,
  styled,
} from '@kivra/react-components';
import { ArrowDownIcon16 } from '@kivra/react-icons';
import React, { useState } from 'react';
import { useAuthenticatedUser } from '../../context/globalContext';
import { DrawerContent } from './DrawerContent';
import { OverviewLogoAndName } from './OverviewLogoAndName';
import { SenderLogoAndName } from './SenderLogoAndName';

export const UserProfileDrawerContainer = styled.div({
  height: '100%',
  marginLeft: '$spacing-8',
  marginRight: '-$spacing-24',
  $medium: {
    marginRight: '-$spacing-16',
  },
});

interface Props {
  onSenderClicked: (senderKey: string) => void;
  isOrganizationOverview?: boolean;
}

export const UserProfileDrawer = ({
  onSenderClicked,
  isOrganizationOverview = false,
}: Props) => {
  const [openSenderMenu, setOpenSenderMenu] = useState(false);
  const { email } = useAuthenticatedUser();

  const onCloseDrawer = () => setOpenSenderMenu(false);
  const onSenderClickHandler = (senderKey: string) => {
    // onCloseDrawer() test without closing drawer
    onSenderClicked(senderKey);
  };
  return (
    <>
      <OpenDrawerButton
        onClick={() => {
          setOpenSenderMenu(true);
        }}
        role="button"
      >
        <InnerContainer data-test-id="UserProfileContainer">
          {isOrganizationOverview ? (
            <OverviewLogoAndName />
          ) : (
            <SenderLogoAndName />
          )}
          <Margin left={8}>
            <Flex alignItems="center">
              <ArrowDownIcon16 color="currentColor" />
            </Flex>
          </Margin>
        </InnerContainer>
      </OpenDrawerButton>
      <NavDrawer
        paddingTop={0}
        variant="temporary"
        anchor="right"
        open={openSenderMenu}
        onClose={onCloseDrawer}
        width={375}
      >
        <DrawerContent
          topElement={
            isOrganizationOverview ? (
              <OverviewLogoAndName />
            ) : (
              <SenderLogoAndName subtitle={email} />
            )
          }
          onSenderSelected={onSenderClickHandler}
          onCloseDrawer={onCloseDrawer}
        />
      </NavDrawer>
    </>
  );
};

const OpenDrawerButton = styled(FocusWrapper)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  height: '100%',
  border: 0,
  borderLeft: `1px solid $border-distinct`,
  padding: [0, '$spacing-16'],
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '$hover-background',
  },
  transition: 'background-color 0.2s ease',
  overflow: 'hidden',
});

const InnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  color: '$text-primary',
});
