import type { Campaign } from '@sdk/campaigns';
import { Body, Caption, ExternalLink, styled } from '@kivra/react-components';
import React from 'react';
import { capitalize } from '../util/capitalize';
import { getCopy } from '../util/copy';
import { pluralDocumentTypeCopy } from '../util/copyKeys';
import { getTimePeriodText, getUpdatedAtString } from '../util/dates';

type Props = {
  campaign: Campaign;
};

export const CampaignReadonlyDetails = ({ campaign }: Props) => {
  const shouldShowPostalSpecifcFields = campaign.documentType !== 'receipt';
  return (
    <DetailsContainer>
      <DetailPair title={getCopy('campaigns__title')} value={campaign.title} />
      {shouldShowPostalSpecifcFields && (
        <>
          <DetailPair
            title={getCopy('campaigns__document_type')}
            value={capitalize(campaign.documentType)}
          />
          <DetailPair title="Position" value={capitalize(campaign.position)} />
        </>
      )}
      <DetailPair
        title={getCopy('campaigns__uploaded_content')}
        value={campaign.image?.split('/').reverse()[0]}
      />
      <DetailPair
        title={getCopy('campaigns__shown_on_document_sent', {
          documentType: pluralDocumentTypeCopy(
            campaign.documentType
          ).toLowerCase(),
        })}
        value={getTimePeriodText(campaign.attachFrom, campaign.attachTo)}
      />
      <DetailPair
        title={getCopy('campaigns__visible')}
        value={getTimePeriodText(campaign.activeFrom, campaign.activeTo)}
      />
      <DetailPair
        title={getCopy('campaigns__link')}
        value={
          campaign.useSso || campaign.destinationUrl ? (
            <>
              {campaign.useSso && (
                <div>{getCopy('campaigns__sso_enabled')}</div>
              )}
              {campaign.destinationUrl && (
                <ExternalLink
                  href={campaign.destinationUrl}
                  text={campaign.destinationUrl}
                />
              )}
              {}
            </>
          ) : (
            '-'
          )
        }
      />
      {shouldShowPostalSpecifcFields && (
        <DetailPair
          title={getCopy('campaigns__target_group')}
          value={capitalize(campaign.targetGroup)}
        />
      )}
      <DetailPair
        title={getCopy(
          campaign.clientStatus === 'draft'
            ? 'campaigns__updated_at'
            : 'campaigns__published_at'
        )}
        value={getUpdatedAtString(campaign.updatedAt, getCopy)}
      />
      {shouldShowPostalSpecifcFields && (
        <DetailPair
          title={getCopy('campaigns__segmentation')}
          value={campaign.segmentation?.filename}
        />
      )}
      {shouldShowPostalSpecifcFields &&
      campaign.segmentation?.rows &&
      campaign.segmentation.rows > 0 ? (
        <DetailMargin>
          <Body size="small" color="$text-secondary">
            {`${campaign.segmentation.rows} ${getCopy(
              'campaigns__personal_or_org_numbers'
            )}`}
          </Body>
        </DetailMargin>
      ) : null}
      <DetailPair title={getCopy('campaigns__id')} value={campaign.id} />
    </DetailsContainer>
  );
};

const DetailPair = ({
  title,
  value,
}: {
  title: string;
  value?: JSX.Element | string;
}) => (
  <DetailMargin>
    <Caption>{title}</Caption>
    <Body size="small" color="$text-secondary">
      {value || '-'}
    </Body>
  </DetailMargin>
);

const DetailsContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexFlow: 'column',
  alignContent: 'flex-start',
  flexWrap: 'wrap',
  '& p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const DetailMargin = styled('div')({
  width: 260,
  marginBottom: '$spacing-20',
  marginRight: '$spacing-8',
  '&:last-of-type': {
    marginBottom: 0,
  },
});
