import { Body, css, Flex, StyleException } from '@kivra/react-components';
import React from 'react';
import { usePreviewCampaign } from '../../context/previewContext';
import { getCopy } from '../../util/copy';
import { getDocumentTypeText } from '../../util/copyKeys';
import { DocumentShape } from './utilComponents';
import { DOCUMENT_WIDTH_DESKTOP } from './utils/constants';

type Props = { documentWidth: number };

export const Document = ({ documentWidth }: Props) => {
  const { documentType } = usePreviewCampaign();
  // A4 format height 2^(1/2) of width ~ 1.41
  const height = documentWidth * 1.41;
  const ratio = documentWidth / DOCUMENT_WIDTH_DESKTOP;
  const pixelCalc = (pixels: number) => `${ratio * pixels}px`;

  const getBodyMediumShape = (width: number) => (
    <DocumentShape
      style={{ margin: `${pixelCalc(8)} 0` }}
      width={pixelCalc(width)}
      height={pixelCalc(11)}
    />
  );

  const subtitleShape = (
    <DocumentShape
      style={{ margin: `${pixelCalc(4)} 0` }}
      width={pixelCalc(100)}
      height={pixelCalc(12)}
    />
  );

  const getCaptionShape = (width: number) => (
    <DocumentShape
      style={{ margin: `${pixelCalc(4)} 0` }}
      width={pixelCalc(width)}
      height={pixelCalc(6)}
    />
  );

  return (
    <div
      className={css({
        width: documentWidth,
        height: height,
        backgroundColor: '!!$white',
        borderRadius: pixelCalc(8),
        padding: StyleException(`${pixelCalc(20)} ${pixelCalc(64)}`),
        boxShadow: '$shadow-card',
        marginBottom: StyleException(pixelCalc(20)),
      })}
    >
      <Flex
        style={{ height: '100%' }}
        justifyContent="space-between"
        direction="column"
      >
        <div>
          <Flex style={{ marginBottom: pixelCalc(16) }} justifyContent="center">
            <Body size="medium">
              <span style={{ color: '#212121', fontSize: pixelCalc(24) }}>
                {`${getCopy('campaigns__document', 1)}: ${getDocumentTypeText(
                  documentType
                )}`}
              </span>
            </Body>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <DocumentShape width={pixelCalc(62)} height={pixelCalc(62)} />
            <Flex alignItems="center" direction="column">
              {subtitleShape}
              {subtitleShape}
              {subtitleShape}
            </Flex>
          </Flex>
          <DocumentShape
            style={{ marginTop: pixelCalc(40) }}
            width={pixelCalc(196)}
            height={pixelCalc(22)}
          />
          {getBodyMediumShape(430)}
          {getBodyMediumShape(390)}
          {getBodyMediumShape(430)}
          {getBodyMediumShape(390)}
          <div style={{ padding: pixelCalc(12) }} />
          {getBodyMediumShape(430)}
          {getBodyMediumShape(390)}
          {getBodyMediumShape(430)}
          {getBodyMediumShape(390)}
          <div style={{ padding: pixelCalc(12) }} />
          {getBodyMediumShape(130)}
          {getBodyMediumShape(100)}
        </div>
        <div>
          <Flex
            style={{ marginBottom: pixelCalc(50) }}
            justifyContent="flex-end"
          >
            <DocumentShape
              style={{ padding: `${pixelCalc(6)} ${pixelCalc(16)}` }}
              width={pixelCalc(220)}
              height={pixelCalc(85)}
              borderRadius={pixelCalc(8)}
            >
              {getBodyMediumShape(100)}
              {getCaptionShape(150)}
              {getCaptionShape(120)}
              {getCaptionShape(150)}
            </DocumentShape>
          </Flex>
          <DocumentShape width="100%" height={pixelCalc(6)} />
          <Flex
            alignItems="center"
            style={{ width: '100%' }}
            justifyContent="space-between"
          >
            {getCaptionShape(40)}
            {getCaptionShape(66)}
            {getCaptionShape(50)}
            {getCaptionShape(40)}
          </Flex>
        </div>
      </Flex>
    </div>
  );
};
