import { Select, useOnMount } from '@kivra/react-components';
import type { ClientStatusType } from '@sdk/campaigns';
import React, { ComponentProps } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  CampaignForm,
  CampaignFormKey,
} from '../../../../../types/campaignForm';
import { isOngoing } from '../../../../../util/campaignStatus';
import { Block } from '../Block';
import { getBlockProps } from '../utils/getBlockProps';

interface Props {
  campaignStatus: ClientStatusType;
  name: Extract<CampaignFormKey, 'documentType' | 'targetGroup' | 'position'>;
  options: ComponentProps<typeof Select>['options'];
}

export const SelectField = ({ campaignStatus, name, options }: Props) => {
  const { control, getValues } = useFormContext<CampaignForm>();
  const { field } = useController({
    name,
    control,
  });

  useOnMount(() => {
    if (!field.value) {
      field.onChange(options[0].value);
  }
  });

  return options.length > 1 ? (
    <Block {...getBlockProps(name, getValues())}>
      <Select
        disabled={isOngoing(campaignStatus)}
        size="small"
        value={field.value || options[0].value}
        options={options}
        onChange={field.onChange}
      />
    </Block>
  ) : null;
};
