import { css } from '@kivra/react-components';
import React from 'react';
import {
  useCalcPixels,
  useCalcPixelsString,
  usePreviewCampaign,
} from '../../context/previewContext';
import { LayoutBase } from './utilComponents';
import { phoneInnerHeight, phoneInnerWidth } from './utils/constants';
import { CampaignImage } from './CampaignImage';
import { Document } from './Document';

export const PhoneLayout = () => {
  const { position } = usePreviewCampaign();
  const calcPixels = useCalcPixels();
  const calcPixelsString = useCalcPixelsString();
  return (
    <LayoutBase
      className={css({
        display: 'flex',
        flexDirection: position === 'top' ? 'column' : 'column-reverse',
        minHeight: calcPixelsString(phoneInnerHeight),
      })}
    >
      <CampaignImage />
      <div style={{ padding: calcPixelsString(4) }}>
        <Document documentWidth={calcPixels(phoneInnerWidth - 8)} />
      </div>
    </LayoutBase>
  );
};
