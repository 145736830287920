import { SenderSearchResult } from '@kivra/senders';
import { DashboardType } from '../types/campaignList';

export const getSender = (senders: SenderSearchResult[]) => (
  senderKey: string
) => {
  return senders.find((s) => s.key === senderKey);
};

export type RouteType = DashboardType | 'campaign' | 'new-campaign';
