import { SenderSearchResult } from '@kivra/senders';
import { createContext, useContext } from 'react';
import { createGetRoute } from '../routes/history';
import { useCurrentOrganization } from './sendersContext';

export interface SenderContext {
  sender: SenderSearchResult;
  isSsoEnabled: boolean;
}

export const SenderContext = createContext<SenderContext>(null as any);

const {
  Consumer: SenderContextConsumer,
  Provider: SenderContextProvider,
} = SenderContext;

const hookCreator = <T>(map: (tc: SenderContext) => T) => (): T => {
  const sender = useContext(SenderContext);
  return map(sender);
};

export const useSender = hookCreator((t) => t.sender);
export const useSso = hookCreator((t) => t.isSsoEnabled);
export const useCreateSenderRoute = hookCreator((t) => {
  const { organizationId } = useCurrentOrganization();
  return createGetRoute({ organizationId, senderKey: t.sender.key });
});
export { SenderContextProvider, SenderContextConsumer };
