import type { CampaignRequest } from '@sdk/campaigns';
import { getCampaign, createCampaign } from './handleUnauthorizedErrorWrapper';

export const duplicateCampaign = (
  organizationId: string,
  senderKey: string,
  campaignId: string
) =>
  getCampaign(organizationId, senderKey, campaignId).then(sourceCampaign => {
    const {
      destinationUrl,
      documentType,
      image,
      position,
      targetGroup,
      useSso,
    } = sourceCampaign;
    const destinationCampaign: CampaignRequest = {
      destinationUrl,
      documentType,
      imageUrl: image || undefined,
      position,
      targetGroup,
      title: `${sourceCampaign.title} copy`,
      useSso,
    };
    return createCampaign(organizationId, senderKey, destinationCampaign);
  });
