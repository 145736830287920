import { styled } from '@kivra/react-components';
import { calcRemFromPx } from '@kivra/react-components/src/theme/util';

export const Textarea = styled.textarea<{ rows: number }>((p) => ({
  background: '$background-secondary',
  border: '1px solid $border-input',
  borderRadius: '$radius-medium',
  boxSizing: 'border-box',
  color: '$text-primary',
  display: 'block',
  fontSize: `${calcRemFromPx(14)}rem`,
  fontFamily: 'inherit',
  fontWeight: 400,
  height: 28 * p.rows,
  lineHeight: `${calcRemFromPx(18)}rem`,
  margin: '$spacing-0',
  outline: '0',
  padding: '$spacing-16',
  resize: 'none',
  WebkitTapHighlightColor: 'transparent',
  width: '100%',
  '&:hover': {
    boxShadow: 'inset 0 0 0 1px $border-input',
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $active-highlight',
    borderColor: '$active-highlight',
  },
}));
