import type { BannerConfig } from '@sdk/campaigns';
import { Caption, Margin, styled } from '@kivra/react-components';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getDefaultBannerOptions } from '../../../../../components/bannerEditor/defaultBannerOptions';
import { useConfig } from '../../../../../context/globalContext';
import { CampaignForm } from '../../../../../types/campaignForm';
import { isOngoing } from '../../../../../util/campaignStatus';
import { getCopy } from '../../../../../util/copy';
import { imageErrorMessages } from '../../../../../util/copyKeys';
import { BannerEditorContainer } from '../BannerEditorContainer';
import { Block } from '../Block';
import { ImageChangeEvent, ImageUpload } from '../ImageUpload';
import { isReceipt } from '../utils/campaignEditor';
import { getBlockProps } from '../utils/getBlockProps';
import { CampaignField } from './fieldType';

export const ImageField: CampaignField = ({ campaignStatus }) => {
  const {
    control,
    formState,
    setError,
    clearErrors,
    watch,
    getValues,
    setValue,
  } = useFormContext<CampaignForm>();
  const { kivra_campaigns_enable_banner_editor: enableEditor } = useConfig();
  const isDisabled = isOngoing(campaignStatus);
  return (
    <Block {...getBlockProps('image', getValues())}>
      <Root>
        <Controller
          name="image"
          control={control}
          defaultValue={''}
          rules={{
            required:
              watch('submitActionType') === 'publish' &&
              getCopy('campaigns__validation_error__image'),
          }}
          render={imageProps => {
            return (
              <ImageUpload
                disabled={isDisabled}
                errorMessage={formState.errors.image?.message}
                onChange={({ value, error }: ImageChangeEvent) => {
                  if (error) {
                    setError('image', {
                      type: error,
                      message: imageErrorMessages(error),
                    });
                  } else {
                    clearErrors('image');
                    setValue('bannerConfig', null, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    imageProps.field.onChange(value);
                  }
                }}
                isReceipt={isReceipt(getValues().documentType)}
              />
            );
          }}
        />
        {enableEditor && (
          <Controller
            name="bannerConfig"
            control={control}
            render={editorProps => (
              <>
                <Margin top={8} bottom={6}>
                  <Caption
                    color={
                      isDisabled ? '$on-surface-disabled' : '$text-secondary'
                    }
                  >
                    {getCopy('campaigns__or')}
                  </Caption>
                </Margin>
                <BannerEditorContainer
                  disabled={isDisabled}
                  options={editorProps.field.value || getDefaultBannerOptions()}
                  onChange={(image: string, options: BannerConfig) => {
                    setValue('image', image, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    editorProps.field.onChange(options);
                  }}
                />
              </>
            )}
          />
        )}
      </Root>
    </Block>
  );
};

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '$spacing-12',
});
