import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getCopy } from '../../../../../util/copy';
import {
  isActiveFromBeforeAttachFrom,
  isActiveFromBeforeToday,
  isActiveToBeforeActiveFrom,
  isActiveToBeforeAttachTo,
  isAttachToBeforeAttachFrom,
  isInThePast,
} from '../../../../../util/dates';
import { Block } from '../Block';
import { DateRangePicker } from '../DateRangePicker';
import { isOngoing } from '../../../../../util/campaignStatus';
import {
  CampaignForm,
  CampaignFormErrors,
} from '../../../../../types/campaignForm';
import { DateError } from '../../../../../types/dateError';
import { getBlockProps } from '../utils/getBlockProps';
import { FormErrorMessage } from '../FormErrorMessage';
import { CampaignField } from './fieldType';

const dateValidationError: Record<string, DateError> = {
  activeToBeforeActiveFrom: {
    copyKey: 'campaigns__date_error_to_before_from',
    inputIds: ['activeTo', 'activeFrom'],
  },
  attachToBeforeAttachFrom: {
    copyKey: 'campaigns__date_error_to_before_from',
    inputIds: ['attachTo', 'attachFrom'],
  },
  activeToBeforeAttachTo: {
    copyKey: 'campaigns__date_error__active_to_before_attach_to',
    inputIds: ['activeTo', 'attachTo'],
  },
  activeFromBeforeAttachFrom: {
    copyKey: 'campaigns__date_error__active_from_before_attach_from',
    inputIds: ['activeFrom', 'attachFrom'],
  },
  activeFromBeforeToday: {
    copyKey: 'campaigns__date_error__active_from_before_today',
    inputIds: ['activeFrom'],
  },
};

export const CampaignDatesBlocks: CampaignField = ({ campaignStatus }) => {
  const { control, watch, trigger, getValues, formState } =
    useFormContext<CampaignForm>();
  const { dates, submitActionType } = watch();

  const isOngoingCampaign = isOngoing(campaignStatus);

  const errorType = (formState.errors as CampaignFormErrors).dates?.type;
  const errorObj = errorType && dateValidationError[errorType];

  const disableAttachField =
    isOngoingCampaign && !!dates.attachTo && !!isInThePast(dates.attachTo);

  return (
    <Controller
      control={control}
      name="dates"
      rules={{
        validate: {
          activeToBeforeActiveFrom: value => !isActiveToBeforeActiveFrom(value),
          attachToBeforeAttachFrom: value => !isAttachToBeforeAttachFrom(value),
          activeFromBeforeToday: value =>
            isOngoingCampaign || !isActiveFromBeforeToday(value),
          activeToBeforeAttachTo: value => !isActiveToBeforeAttachTo(value),
          activeFromBeforeAttachFrom: value =>
            !isActiveFromBeforeAttachFrom(value),
          required: value => {
            if (submitActionType === 'save') {
              return true;
            }
            const impactedFieldsAsCommaSeparatedString =
              Object.values(value).every(value => !!value) ||
              Object.entries(value)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .filter(([key, value]) => !value)
                .map(([key]) => key)
                .join(',');
            return impactedFieldsAsCommaSeparatedString;
          },
        },
      }}
      render={props => {
        const showError = (fieldId: keyof CampaignForm['dates']) =>
          Boolean(errorObj?.inputIds.includes(fieldId)) ||
          Boolean(
            submitActionType === 'publish' && !props.field.value[fieldId]
          );

        const getErrorMessage = (
          dateRangeField: 'attach' | 'active'
        ): string | undefined => {
          const hasError = errorObj?.inputIds?.some(input =>
            input.startsWith(dateRangeField)
          );
          return hasError ? getCopy(errorObj?.copyKey) : undefined;
        };
        const attachErrorMessage = getErrorMessage('attach');
        const activeErrorMessage = getErrorMessage('active');

        return (
          <>
            <Block {...getBlockProps('attach', getValues())}>
              <DateRangePicker
                disabled={disableAttachField}
                fromDate={props.field.value.attachFrom}
                fromDateDisabled={isOngoingCampaign}
                toDateDisablePast={isOngoingCampaign}
                fromError={showError('attachFrom')}
                onChange={(type: 'from' | 'to', date: Date | null) => {
                  if (type === 'from') {
                    props.field.onChange({
                      ...props.field.value,
                      attachFrom: date,
                    });
                  } else {
                    props.field.onChange({
                      ...props.field.value,
                      attachTo: date,
                    });
                  }
                  trigger('dates');
                }}
                toDate={props.field.value.attachTo}
                toError={showError('attachTo')}
              />
              {attachErrorMessage && (
                <FormErrorMessage errorMessage={attachErrorMessage} />
              )}
            </Block>
            <Block {...getBlockProps('active', getValues())}>
              <>
                <DateRangePicker
                  fromDate={props.field.value.activeFrom}
                  fromDateDisabled={isOngoingCampaign}
                  fromDateDisablePast={!isOngoingCampaign}
                  fromError={showError('activeFrom')}
                  onChange={(type: 'from' | 'to', date: Date | null) => {
                    if (type === 'from') {
                      props.field.onChange({
                        ...props.field.value,
                        activeFrom: date,
                      });
                    } else {
                      props.field.onChange({
                        ...props.field.value,
                        activeTo: date,
                      });
                    }
                    trigger('dates');
                  }}
                  minFromDate={props.field.value.attachFrom}
                  toDate={props.field.value.activeTo}
                  toDateDisablePast={true}
                  toError={showError('activeTo')}
                />
                {activeErrorMessage && (
                  <FormErrorMessage errorMessage={activeErrorMessage} />
                )}
              </>
            </Block>
          </>
        );
      }}
    />
  );
};
