import {
  Body,
  Dialog,
  ServiceMessage,
  useTheme,
} from '@kivra/react-components';
import React, { useState } from 'react';
import { WarningCircleIcon48 } from '@kivra/react-icons';
import { deleteCampaign } from '../util/handleUnauthorizedErrorWrapper';
import { useCurrentOrganization } from '../context/sendersContext';
import { useSender } from '../context/senderContext';
import { getCopy } from '../util/copy';

interface Props {
  campaignId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteDialog = ({
  campaignId,
  open,
  onClose,
  onSuccess,
}: Props) => {
  const { key: senderKey } = useSender();
  const { organizationId } = useCurrentOrganization();
  const { getColor } = useTheme();
  const [error, setError] = useState<Error | null>(null);

  const deleteHandler = () => {
    return deleteCampaign(organizationId, senderKey, campaignId)
      .then(() => onSuccess())
      .catch(setError);
  };
  const errorElement = error && (
    <ServiceMessage
      title={getCopy('campaigns__delete_dialog_error_title')}
      variant={'error'}
    >
      {getCopy('campaigns__dialog_error_generic_body')}
    </ServiceMessage>
  );
  return (
    <Dialog.Destructive
      actionText={getCopy('btn__delete')}
      cancelText={getCopy('btn__cancel_dialog')}
      onConfirm={deleteHandler}
      onClose={onClose}
      title={getCopy('campaigns__delete_dialog__title')}
      open={open}
      largeIcon={<WarningCircleIcon48 color={getColor('$error-text')} />}
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onDismissFocusRef={undefined}
    >
      <Body size="medium" gutterBottom>
        {getCopy('campaigns__delete_dialog__body')}
      </Body>
      {errorElement}
    </Dialog.Destructive>
  );
};
