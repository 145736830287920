import type { ClientStatusType } from '@sdk/campaigns';

export function isOngoing(
  statusOrCampaign: ClientStatusType | { clientStatus: ClientStatusType }
): boolean {
  if (typeof statusOrCampaign === 'string') {
    return statusOrCampaign === 'ongoing';
  }
  return statusOrCampaign?.clientStatus === 'ongoing';
}
