import React from 'react';
import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router';
import { isAuthenticated } from '@kivra/identity';
import { history } from './history';

interface Props extends RouteProps {
  component: any;
}

export const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  useRouteMatch();
  if (!isAuthenticated()) {
    // If not authenticated user is sent to login for auto login. Then redirected to expected route by onLoginRoute.
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { onLoginRoute: history.location.pathname },
        }}
      />
    );
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
