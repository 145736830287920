import type { ClientStatusType } from '@sdk/campaigns';
import { useServiceCaller } from './useServiceCaller';
import {
  getAdminOrganizationCampaigns,
  getCampaigns,
  getOrganizationCampaigns,
} from './handleUnauthorizedErrorWrapper';

type Args = {
  organizationId: string;
  senderKey?: string;
  types: ClientStatusType[];
  isAdmin?: boolean;
};

export const campaignServiceStates = ({
  organizationId,
  senderKey,
  types,
  isAdmin = false,
}: Args) => {
  // Start fetching lists of 1 or more type of campaigns. Used for ongoing and upcoming page.
  return types.map(type => {
    const [serviceState, callService] = useServiceCaller(() => {
      if (isAdmin) {
        return getAdminOrganizationCampaigns(type);
      } else if (!senderKey) {
        return getOrganizationCampaigns(organizationId, type);
      }
      return getCampaigns(organizationId, senderKey, type);
    }, false);
    return { type, serviceState, callService };
  });
};
