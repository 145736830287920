import React from 'react';
import { styled, StyleException, IconButton } from '@kivra/react-components';
import { DesktopIcon16, PhoneIcon16 } from '@kivra/react-icons';
import { PreviewType } from '../../types/previewType';

interface Props {
  previewType: PreviewType;
  onDeviceSelected: (device: PreviewType) => void;
}

export const SelectDevice = ({ onDeviceSelected, previewType }: Props) => {
  return (
    <ToggleGroup>
      <ToggleButton
        icon={PhoneIcon16}
        selected={previewType === 'phone'}
        onClick={() => onDeviceSelected('phone')}
      />
      <ToggleButton
        icon={DesktopIcon16}
        selected={previewType === 'desktop'}
        onClick={() => onDeviceSelected('desktop')}
      />
    </ToggleGroup>
  );
};

const ToggleGroup = styled('div')({
  display: 'inline-flex',
  borderRadius: '$radius-small',
  overflow: 'hidden',
});

const ToggleButton = styled(IconButton)<{ selected: boolean }>(
  ({ selected }) => ({
    padding: StyleException('6px 10px'),
    minWidth: 'auto',
    minHeight: 'auto',
    backgroundColor: selected ? '$background-primary' : '!!$gray100',
    color: selected ? '$text-primary' : '$text-inverted',
    borderRadius: 0,
    pointerEvents: selected ? 'none' : 'auto',
  })
);
