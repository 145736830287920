import { createContext, useContext } from 'react';
import type {
  Campaign,
  CampaignRequest,
  ClientStatusType,
  GetOverlappingCampaignsPayload,
  SegmentationJobInformation,
  SegmentationJobRequest,
} from '@sdk/campaigns';

export interface CampaignContext {
  campaign?: Campaign;
  activateCampaign: (id: string) => Promise<void>;
  saveCampaign: (changes?: CampaignRequest) => Promise<Campaign>;
  deleteCampaign: () => Promise<void>;
  getOverlappingCampaigns: (
    campaign: Omit<GetOverlappingCampaignsPayload, 'id' | 'hasSegmentation'>
  ) => Promise<Campaign[]>;
  createSegmentationJob: (
    segmenation: SegmentationJobRequest,
    campaignId: string
  ) => Promise<SegmentationJobInformation>;
  deleteSegmentation: () => Promise<void>;
}

export const CampaignContext = createContext<CampaignContext>(null as any);

const { Consumer: CampaignContextConsumer, Provider: CampaignContextProvider } =
  CampaignContext;

export const useCampaignStatus = (): ClientStatusType =>
  useContext(CampaignContext).campaign?.clientStatus || 'draft';
export function useCampaign(): Required<CampaignContext>;
export function useCampaign(throwIfUndefined: true): Required<CampaignContext>;
export function useCampaign(throwIfUndefined: false): CampaignContext;
export function useCampaign(throwIfUndefined = true) {
  const c = useContext(CampaignContext);
  if (throwIfUndefined && !c.campaign) {
    throw new Error('Campaign is undefined');
  }
  return c;
}
export { CampaignContextProvider, CampaignContextConsumer };
