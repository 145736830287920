import React from 'react';
import {
  Margin,
  Button,
  NavDrawerMenu,
  styled,
  useIsMediumScreenSize,
} from '@kivra/react-components';
import {
  PreviousIcon,
  SignIcon,
  OngoingIcon,
  PlusIcon,
} from '@kivra/react-icons';
import { useCreateSenderRoute } from '../../../context/senderContext';
import { goTo } from '../../../routes/history';
import { DashboardType } from '../../../types/campaignList';
import { getCopy } from '../../../util/copy';
import { CampaignsNavDrawer } from '../../../components/CampaignsNavDrawer';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedMenuType: DashboardType;
}

export const DashboardDrawer = ({ open, onClose, selectedMenuType }: Props) => {
  const createRoute = useCreateSenderRoute();
  const isMediumSize = useIsMediumScreenSize();
  const onClick = (id: string) => {
    goTo(createRoute({ id } as { id: DashboardType }));
  };
  const campaignMenuItems = [
    {
      id: 'ongoing-and-upcoming',
      iconComponent: OngoingIcon,
      label: `${getCopy('campaigns__status__ongoing')} & ${getCopy(
        'campaigns__status__upcoming'
      )}`,
    },
    {
      id: 'draft',
      iconComponent: SignIcon,
      label: getCopy('campaigns__status__draft'),
    },
    {
      id: 'previous',
      iconComponent: PreviousIcon,
      label: getCopy('campaigns__status__previous'),
    },
  ];

  return (
    <CampaignsNavDrawer
      variant={isMediumSize ? 'temporary' : 'permanent'}
      open={open}
      onClose={onClose}
    >
      <Margin left={16} right={16} bottom={16} top={40}>
        <CreateButton
          onClick={() => goTo(createRoute({ id: 'new-campaign' }))}
          variant="primary"
          size="medium"
          icon={() => <PlusIcon color="currentColor" />}
        >
          {getCopy('btn__create_new')}
        </CreateButton>
      </Margin>
      <NavDrawerMenu
        onClick={onClick}
        selectedId={selectedMenuType}
        menuItems={campaignMenuItems}
      />
    </CampaignsNavDrawer>
  );
};

const CreateButton = styled(Button)({ minWidth: '100%' });
