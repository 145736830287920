import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { getQueryParams } from './bootstrap/queryParams';

function renderApp() {
  const queryParams = getQueryParams(window.location.href);

  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(<App queryParams={queryParams} />);
}

try {
  renderApp();
} catch (error) {
  console.error(error);
  document.body.innerHTML = `
    <div style="text-align:center; width:100%; padding-top:5vh;">
      <h2>Något gick fel när applikationen skulle startas.</h2>
      <h4>Om felet kvarstår kontakta support@kivra.com</h4>
    </div>
  `;
}
