import React from 'react';
import { Controller, useFormContext, ValidateResult } from 'react-hook-form';
import { Caption, Checkbox, Margin, TextField } from '@kivra/react-components';
import { Block } from '../Block';
import { getCopy } from '../../../../../util/copy';
import { useSso } from '../../../../../context/senderContext';
import { isOngoing } from '../../../../../util/campaignStatus';
import { getBlockProps } from '../utils/getBlockProps';
import { destinationUrlDefaultValue } from '../utils/createCampaignForm';
import { CampaignForm } from '../../../../../types/campaignForm';
import { CampaignField } from './fieldType';

export const LinkField: CampaignField = ({ campaignStatus }) => {
  const { control, formState, watch, trigger, getValues } =
    useFormContext<CampaignForm>();
  const showSso = useSso() || watch('useSso');

  const isEmptyOrDefault = () => {
    const url = watch('destinationUrl');
    return url === '' || url === destinationUrlDefaultValue;
  };

  const httpsValidation = (value: string): ValidateResult =>
    value.startsWith('https://') || getCopy('misc__validate_url_error_https');

  const isValidUrl = (value: string): boolean => {
    let url;
    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }

    return url.protocol === 'https:';
  };

  const characterValidation = (value: string): ValidateResult => {
    const illegalCharacterRegex =
      /[^A-Za-z0-9\:\/\?\#\[\]\@\!\$\&\'\(\)\*\+\,\;\=\.\-\_\~\%]/g;
    const illegalCharacters = value.match(illegalCharacterRegex);
    if (illegalCharacters && illegalCharacters.length > 0) {
      return getCopy('misc__validate_url_error_illegal_characters');
    }
  };

  const formatValidation = (value: string): ValidateResult => {
    return isValidUrl(value) || getCopy('misc__validate_url_error_format');
  };

  const validationRules = isEmptyOrDefault()
    ? {}
    : {
        validate: {
          https: httpsValidation,
          characters: characterValidation,
          format: formatValidation,
        },
      };

  const isOngoingCampaign = isOngoing(campaignStatus);
  return (
    <Block {...getBlockProps('destinationUrl', getValues())}>
      <Controller
        name="destinationUrl"
        control={control}
        rules={validationRules}
        render={props => {
          const errorType = formState.errors.destinationUrl?.type as
            | 'https'
            | 'characters'
            | 'format';
          const validationMessage =
            formState.errors.destinationUrl?.message || '';
          return (
            <TextField
              disabled={isOngoingCampaign}
              size="small"
              value={props.field.value}
              onTextChange={props.field.onChange}
              onBlur={() => {
                props.field.onBlur();
              }}
              errorMessage={errorType ? validationMessage : undefined}
            />
          );
        }}
      />
      {showSso && (
        <Controller
          name="useSso"
          control={control}
          render={props => {
            const onChange = (value: boolean) => {
              props.field.onChange(value);
              trigger('destinationUrl');
            };
            return (
              <Margin top={8}>
                <Checkbox
                  disabled={isOngoingCampaign}
                  label={getCopy('campaigns__enable_sso_label')}
                  checked={props.field.value}
                  onChange={onChange}
                />
                <Margin top={8}>
                  <Caption>{getCopy('campaigns__enable_sso_hint')}</Caption>
                </Margin>
              </Margin>
            );
          }}
        />
      )}
    </Block>
  );
};
