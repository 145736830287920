import {
  ApiResponseError,
  convertPropertyNamesToCamelCase,
  convertPropertyNamesToSnakeCase,
  kampanhaRequest,
  serializeQueryParams,
} from '@kivra/common';
import { userSession } from '@kivra/identity';
import type {
  Campaign,
  CampaignRequest,
  ClientStatusType,
  GetOverlappingCampaignsPayload,
} from './types';
import { CampaignSignup } from './types/campaignSignup';
import { CampaignStatistics } from './types/statistics';

/**
 * Creates a campaign in backend.
 */
export const createCampaign = (
  organizationId: string,
  senderKey: string,
  campaign?: CampaignRequest
): Promise<Campaign> => {
  return kampanhaRequest
    .post<any>({
      path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign`,
      accessToken: userSession.getToken(),
      payload: campaign && convertPropertyNamesToSnakeCase(campaign),
    })
    .then(response => convertPropertyNamesToCamelCase(response) as Campaign);
};

/**
 * Updates a campaign in backend.
 */
export const updateCampaign = (
  organizationId: string,
  senderKey: string,
  campaignKey: string,
  campaign: CampaignRequest
): Promise<Campaign> => {
  return kampanhaRequest
    .patch<any>({
      path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/${campaignKey}`,
      accessToken: userSession.getToken(),
      payload: convertPropertyNamesToSnakeCase(campaign),
    })
    .then(response => convertPropertyNamesToCamelCase(response) as Campaign);
};

/**
 * Get campaigns. If status is sent, only the campaigns with that status will be returned.
 */
export const getCampaigns = (
  organizationId: string,
  senderKey: string,
  listType?: ClientStatusType
): Promise<Campaign[]> => {
  const campaignRoute = listType ? `campaign?status=${listType}` : 'campaign';
  return kampanhaRequest
    .get<any[]>({
      path: `/v2/organization/${organizationId}/sender/${senderKey}/${campaignRoute}`,
      accessToken: userSession.getToken(),
    })
    .then(response =>
      response.map(
        campaignResponse =>
          convertPropertyNamesToCamelCase(campaignResponse) as Campaign
      )
    );
};

/**
 * Get a campaign.
 */
export const getCampaign = (
  organizationId: string,
  senderKey: string,
  campaignKey: string
): Promise<Campaign> => {
  return kampanhaRequest
    .get<any>({
      path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/${campaignKey}`,
      accessToken: userSession.getToken(),
    })
    .then(
      (response: any) => convertPropertyNamesToCamelCase(response) as Campaign
    );
};

/**
 * Get all campaigns for a given organization
 */
export const getOrganizationCampaigns = (
  organizationId: string,
  status: ClientStatusType,
  limit?: number,
  offset?: number
): Promise<Campaign[]> => {
  const queryParams: Record<string, any> = {
    status: String(status),
  };
  if (limit) queryParams[limit] = limit;
  if (offset) queryParams[offset] = offset;
  return kampanhaRequest
    .get({
      path: `/v2/organization/${organizationId}/campaign${serializeQueryParams(
        queryParams
      )}`,
      accessToken: userSession.getToken(),
    })
    .then(
      (response: any) =>
        response.map(convertPropertyNamesToCamelCase) as Campaign[]
    );
};

/**
 * Get all campaigns that the admin organization has read access to
 */
export const getAdminOrganizationCampaigns = (
  status: ClientStatusType,
  limit?: number,
  offset?: number
): Promise<Campaign[]> => {
  const queryParams: Record<string, any> = {
    status: String(status),
  };
  if (limit) queryParams[limit] = limit;
  if (offset) queryParams[offset] = offset;
  return kampanhaRequest
    .get<Campaign[]>({
      path: `/v2/admin/campaign${serializeQueryParams(queryParams)}`,
      accessToken: userSession.getToken(),
    })
    .then(
      (response: any) =>
        response.map(convertPropertyNamesToCamelCase) as Campaign[]
    );
};

/**
 * Delete a campaign.
 */
export const deleteCampaign = (
  organizationId: string,
  senderKey: string,
  campaignKey: string
): Promise<void> => {
  return kampanhaRequest.delete({
    path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/${campaignKey}`,
    accessToken: userSession.getToken(),
  });
};

/**
 * Activate a campaign, so that the Campaign will be shown on content in Kivra.
 */
export const activateCampaign = (
  organizationId: string,
  senderKey: string,
  campaignKey: string
): Promise<void> => {
  return kampanhaRequest.post({
    path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/${campaignKey}/activate`,
    accessToken: userSession.getToken(),
    payload: {},
  });
};

/**
 * Deactivate a already active campaign, so that the Campaign will not be shown
 * on content in Kivra.
 */
export const deactivateCampaign = (
  organizationId: string,
  senderKey: string,
  campaignKey: string
): Promise<void> => {
  return kampanhaRequest.post({
    path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/${campaignKey}/deactivate`,
    accessToken: userSession.getToken(),
    payload: {},
  });
};

/**
 * Check if a campaign link is valid. Checks pattern and if the link is
 * whitelisted in backend.
 */
export const isCampaignLinkValid = (link: string): Promise<boolean> => {
  return kampanhaRequest.get({
    path: `/v2/redirect/validate?destination=${encodeURIComponent(link)}`,
    accessToken: userSession.getToken(),
  });
};

/**
 * The service will send signup information to a Kivra Campaigns email
 * address. After that a account will be created manually.
 */
export const sendSignupEmail = (signupData: CampaignSignup): Promise<void> => {
  return kampanhaRequest.post({
    path: `/v1/util/campaign_signup`,
    accessToken: userSession.getToken(),
    payload: convertPropertyNamesToSnakeCase(signupData),
  });
};

/**
 * The service will fetch campaigns that will overlap with the campaign values sent in the body.
 * If an id is provided, that campaign will be omitted from the resulting list.
 * If an empty array is returned, there are no overlapping campaigns.
 */
export const getOverlappingCampaigns = (
  organizationId: string,
  senderKey: string,
  campaign: GetOverlappingCampaignsPayload
): Promise<Campaign[]> => {
  const { id, ...body } = campaign;
  return kampanhaRequest.post({
    path: `/v2/organization/${organizationId}/sender/${senderKey}/overlap`,
    accessToken: userSession.getToken(),
    payload: convertPropertyNamesToSnakeCase({
      ...body,
      campaignKey: id,
    }),
  });
};

/**
 * Service will fetch campaigns statistics for ongoing and previous campaigns.
 * Statistics includes clicked, num_contents and served.
 * 202 is returned if the statistics is not ready for consumption yet. This is
 * treated as an error for the sake of easier handling in the client.
 */
export const getCampaignStatistics = (
  organizationId: string,
  senderKey: string,
  campaignKey: string
): Promise<CampaignStatistics> => {
  return kampanhaRequest
    .get({
      path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/${campaignKey}/stats`,
      accessToken: userSession.getToken(),
    })
    .then((response: any) => {
      if (response?.code?.startsWith('202')) {
        throw new ApiResponseError({
          httpStatusCode: 202,
          data: response,
        });
      }
      return convertPropertyNamesToCamelCase(response) as CampaignStatistics;
    });
};

export const getIsSsoEnabled = (
  organizationId: string,
  senderKey: string
): Promise<{ hasSso: boolean }> => {
  return kampanhaRequest
    .get<{ has_sso: boolean }>({
      path: `/v2/organization/${organizationId}/sender/${senderKey}/sso`,
      accessToken: userSession.getToken(),
    })
    .then(response => convertPropertyNamesToCamelCase(response));
};
