import React from 'react';
import { Body, Flex } from '@kivra/react-components';
import {
  CampaignTableProps,
  CampaignTableColumns,
} from '../../../../types/table';
import { TitleImageCell } from '../../../../components/TitleImageCell';
import { getCopy } from '../../../../util/copy';
import { I } from '../../../../components/Italic';

interface Props {
  tableRow: CampaignTableProps;
  column: CampaignTableColumns;
}

export const CampaignCellMapper = ({ column, tableRow }: Props) => {
  switch (column) {
    case 'title':
      return <TitleImageCell title={tableRow.title} image={tableRow.image} />;
    case 'timePeriod':
      return <Body size={'small'}>{tableRow.timePeriodText}</Body>;
    case 'updatedAt':
      return <Body size={'small'}>{tableRow.updatedAtText}</Body>;
    case 'documentType':
      return <Body size={'small'}>{tableRow.documentType}</Body>;
    case 'position':
      return <Body size={'small'}>{tableRow.position}</Body>;
    case 'segmentation':
      return <Body size={'small'}>{tableRow.segmentation}</Body>;
    case 'publishedAt':
      return <Body size={'small'}>{tableRow.updatedAtText}</Body>;
    case 'info':
      return (
        <Flex direction="column">
          <Body size={'small'}>
            {tableRow.segmentation === '-' ? (
              getCopy('campaigns__no_segmentation')
            ) : (
              <>
                {`${getCopy('campaigns__segmentation')}: `}
                <I>{tableRow.segmentation}</I>
              </>
            )}
          </Body>
          <Body size={'small'}>
            {`${getCopy('campaigns__position')}: `}
            <I>{tableRow.position}</I>
          </Body>
        </Flex>
      );
    default:
      return <Body size={'small'}>{''}</Body>;
  }
};
