import { ApiResponseError } from '@kivra/common';
import {
  activateCampaign as activateCampaignFeSdk,
  getCampaign as getCampaignFeSdk,
  getCampaigns as getCampaignsFeSdk,
  createCampaign as createCampaignsFeSdk,
  updateCampaign as updateCampaignFeSdk,
  deleteCampaign as deleteCampaignFeSdk,
  deactivateCampaign as deactivateCampaignFeSdk,
  deleteSegmentation as deleteSegmentationFeSdk,
  createSegmentationJob as createSegmentationJobFeSdk,
  getSegmentationJobStatus as getSegmentationJobStatusFeSdk,
  getOverlappingCampaigns as getOverlappingCampaignsFeSdk,
  getCampaignStatistics as getCampaignStatisticsFeSdk,
  getOrganizationCampaigns as getOrganizationCampaignsFeSdk,
  getAdminOrganizationCampaigns as getAdminOrganizationCampaignsFeSdk,
  getIsSsoEnabled as getIsSsoEnabledFeSdk,
} from '@sdk/campaigns';
import { logoutUser } from './authentication';

export const renewAccessToken = async () => {
  logoutUser({ shouldClearTenantStorage: false, shouldNavigate: false });
  window.location.reload();
};

export const handleUnauthenticatedRequest = <
  OriginalRequest extends (...args: any[]) => Promise<any>
>(
  request: OriginalRequest
) => (...args: Parameters<typeof request>): ReturnType<OriginalRequest> =>
  request(...args).catch((error: ApiResponseError) => {
    if (error.httpStatusCode === 401) {
      renewAccessToken();
    } else {
      throw error;
    }
  }) as ReturnType<OriginalRequest>;

export const activateCampaign = handleUnauthenticatedRequest(
  activateCampaignFeSdk
);

export const getCampaign = handleUnauthenticatedRequest(getCampaignFeSdk);

export const createCampaign = handleUnauthenticatedRequest(
  createCampaignsFeSdk
);

export const updateCampaign = handleUnauthenticatedRequest(updateCampaignFeSdk);

export const deleteCampaign = handleUnauthenticatedRequest(deleteCampaignFeSdk);

export const deactivateCampaign = handleUnauthenticatedRequest(
  deactivateCampaignFeSdk
);

export const getCampaigns = handleUnauthenticatedRequest(getCampaignsFeSdk);

export const getOrganizationCampaigns = handleUnauthenticatedRequest(
  getOrganizationCampaignsFeSdk
);

export const getAdminOrganizationCampaigns = handleUnauthenticatedRequest(
  getAdminOrganizationCampaignsFeSdk
);

export const deleteSegmentation = handleUnauthenticatedRequest(
  deleteSegmentationFeSdk
);

export const createSegmentationJob = handleUnauthenticatedRequest(
  createSegmentationJobFeSdk
);

export const getSegmentationJobStatus = handleUnauthenticatedRequest(
  getSegmentationJobStatusFeSdk
);

export const getOverlappingCampaigns = handleUnauthenticatedRequest(
  getOverlappingCampaignsFeSdk
);

export const getCampaignStatistics = handleUnauthenticatedRequest(
  getCampaignStatisticsFeSdk
);

export const getIsSsoEnabled = handleUnauthenticatedRequest(
  getIsSsoEnabledFeSdk
);
