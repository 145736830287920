import { Dialog, ServiceMessage, useTheme } from '@kivra/react-components';
import React, { useState } from 'react';
import { ApiResponseError } from '@kivra/common';
import { WarningCircleIcon48 } from '@kivra/react-icons';
import { useCampaign } from '../../../../../context/campaignContext';
import { getCopy } from '../../../../../util/copy';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteDialog = ({ onClose, onSuccess }: Props) => {
  const { deleteSegmentation } = useCampaign();
  const { getColor } = useTheme();
  const [error, setError] = useState<ApiResponseError>();

  const deleteHandler = () => {
    return deleteSegmentation()
      .then(onSuccess)
      .catch((error) => setError(error));
  };

  const errorElement = error && (
    <ServiceMessage
      title={getCopy('campaigns__delete_segmentation_error')}
      variant={'error'}
    >
      {getCopy('campaigns__dialog_error_generic_body')}
    </ServiceMessage>
  );
  return (
    <Dialog.Destructive
      actionText={getCopy('btn__delete')}
      cancelText={getCopy('btn__cancel_dialog')}
      onConfirm={deleteHandler}
      onClose={onClose}
      title={getCopy('campaigns__delete_segmentation_title')}
      open={true}
      largeIcon={<WarningCircleIcon48 color={getColor('$error-text')} />}
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onDismissFocusRef={undefined}
    >
      {errorElement}
    </Dialog.Destructive>
  );
};
