import React from 'react';
import type { PositionType } from '@sdk/campaigns';
import { getCopy } from '../../../../../util/copy';
import { CampaignField } from './fieldType';
import { SelectField } from './SelectField';

export const PositionField: CampaignField = props => {
  const positionOptions: { label: string; value: PositionType }[] = [
    { label: getCopy('campaigns__position__top'), value: 'top' },
    { label: getCopy('campaigns__position__bottom'), value: 'bottom' },
  ];
  return <SelectField name="position" options={positionOptions} {...props} />;
};
