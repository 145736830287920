import React, { useState, useRef } from 'react';
import { Button, Dialog, styled, useTheme } from '@kivra/react-components';
import { PaletteIcon16 } from '@kivra/react-icons';
import type { BannerConfig } from '@sdk/campaigns';
import { getCopy } from '../../../../util/copy';
import { BannerEditor } from '../../../../components/bannerEditor/BannerEditor';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';

type Props = {
  onChange: (image: string, options: BannerConfig) => void;
  options: BannerConfig;
  disabled: boolean;
};

export const BannerEditorContainer = ({
  onChange,
  disabled,
  options,
}: Props) => {
  const [showBannerTool, setShowBannerTool] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const showBannerToolButtonRef = useRef<HTMLButtonElement>(null);
  const { getColor } = useTheme();

  const onImageChange = (image: string, options: BannerConfig) => {
    onChange(image, options);
    setShowBannerTool(false);
  };

  const onClose = () => {
    if (isDirty) {
      setShowCancelDialog(true);
    } else {
      setShowBannerTool(false);
    }
  };

  const onEdit = (newOptions: BannerConfig) => {
    if (isEqualJSON(newOptions, options)) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  };

  return (
    <>
      <Root>
        <Button
          disabled={disabled}
          variant="link"
          size="small"
          onClick={() => setShowBannerTool(true)}
          icon={() => (
            <PaletteIcon16
              color={disabled ? getColor('$surface-disabled') : 'currentColor'}
            />
          )}
          ref={showBannerToolButtonRef}
        >
          {getCopy('campaigns__create_banner')}
        </Button>
      </Root>
      <Dialog.Passive
        title={getCopy('campaigns__banner_editor_create')}
        open={showBannerTool}
        onClose={onClose}
        ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
        onDismissFocusRef={showBannerToolButtonRef}
        width="stretched"
      >
        <BannerEditor
          onSave={onImageChange}
          options={options}
          onEdit={onEdit}
        />
      </Dialog.Passive>
      {showCancelDialog && (
        <UnsavedChangesDialog
          open={true}
          onClose={() => setShowCancelDialog(false)}
          onAccept={() => {
            setShowCancelDialog(false);
            setShowBannerTool(false);
          }}
        />
      )}
    </>
  );
};

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: [0, '$spacing-8'],
});

const isEqualJSON = (a: Record<string, unknown>, b: Record<string, unknown>) =>
  JSON.stringify(a) === JSON.stringify(b);
