import {
  Dialog,
  ServiceMessage,
  Body,
  Flex,
  useOnMount,
  Margin,
  styled,
} from '@kivra/react-components';
import React, { useState } from 'react';
import type { Campaign } from '@sdk/campaigns';
import { pick } from '@kivra/common';
import { captureException } from '@kivra/log';
import { goTo } from '../../../../routes/history';
import { useCreateSenderRoute } from '../../../../context/senderContext';
import {
  useCampaign,
  useCampaignStatus,
} from '../../../../context/campaignContext';
import { useLoadingOverlay } from '../../../../context/LoadingOverlayProvider';
import { getTimePeriodText } from '../../../../util/dates';
import { pluralDocumentTypeCopy } from '../../../../util/copyKeys';
import { getCopy } from '../../../../util/copy';
import { PublishableCampaign } from './utils/publishableCampaign';

interface Props {
  save: () => Promise<any>;
  onClose: () => void;
  campaign: PublishableCampaign;
}

const errorCopyKeys = {
  publish: {
    title: 'campaigns__publish_dialog_error_title',
    body: 'campaigns__dialog_error_generic_body',
  },
  overlap: {
    title: 'campaigns__overlap_error_title',
    body: 'campaigns__overlap_error_body',
  },
} as const;

export const PublishDialog = ({ campaign, onClose, save }: Props) => {
  const { getOverlappingCampaigns, activateCampaign } = useCampaign(false);
  const clientStatus = useCampaignStatus();
  const createRoute = useCreateSenderRoute();
  const [error, setError] = useState<keyof typeof errorCopyKeys | undefined>();
  const { isLoading, setIsLoading } = useLoadingOverlay();
  const [overlappingCampaigns, setOverlappingCampaigns] = useState<Campaign[]>(
    []
  );

  useOnMount(async () => {
    setIsLoading(true);
    try {
      const campaigns = await getOverlappingCampaigns(
        pick(campaign, [
          'attachFrom',
          'attachTo',
          'activeFrom',
          'activeTo',
          'documentType',
          'position',
          'targetGroup',
        ])
      );
      setOverlappingCampaigns(campaigns);
    } catch (error) {
      setError('overlap');
    }
    setIsLoading(false);
  });

  const saveAndPublish = async () => {
    setError(undefined);
    try {
      const { id } = await save();
      await activateCampaign(id);
      goTo(createRoute({ id: 'ongoing-and-upcoming' }));
    } catch (error) {
      captureException(error as any);
      setError('publish');
    }
  };

  const getTitlesText = (campaigns: Campaign[]): string =>
    campaigns
      .map(c => `"${c.title || getCopy('campaigns__title_placeholder')}"`)
      .join(', ');

  const overlappingCampaignsElement = overlappingCampaigns.length > 0 && (
    <Margin top={24}>
      <ServiceMessage variant="info">
        {getCopy('campaigns__overlapping_campaigns', {
          campaigns: getTitlesText(overlappingCampaigns),
          fields: getCopy(
            campaign.documentType === 'receipt'
              ? 'campaigns__overlapping_fields_receipt'
              : 'campaigns__overlapping_fields_postal'
          ),
        })}
      </ServiceMessage>
    </Margin>
  );

  const errorElement = error && (
    <Margin top={24}>
      <ServiceMessage
        title={getCopy(errorCopyKeys[error].title)}
        variant={'error'}
      >
        {getCopy(errorCopyKeys[error].body)}
      </ServiceMessage>
    </Margin>
  );

  const actionText = getCopy(
    clientStatus === 'draft' ? 'btn__publish' : 'btn__update'
  );

  const DialogComponent = error ? Dialog.Passive : Dialog.Confirmation;

  return (
    <DialogComponent
      dataTestId="PublishDialog"
      actionText={actionText}
      cancelText={getCopy('btn__cancel_dialog')}
      onConfirm={saveAndPublish}
      onClose={onClose}
      title={getCopy('campaigns__publish_dialog_title', {
        action: actionText,
        title: campaign.title || getCopy('campaigns__title_placeholder'),
      })}
      open={!isLoading}
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onDismissFocusRef={undefined}
    >
      <Flex direction="column">
        <Body size="medium" gutterBottom>
          {getCopy('campaigns__campaign_will_be')}:
        </Body>
        <StyledList>
          {campaign.attachFrom && campaign.attachTo && (
            <li>
              <Body size="medium">
                {`${getCopy('campaigns__shown', {
                  documentType: pluralDocumentTypeCopy(
                    campaign.documentType
                  ).toLocaleLowerCase(),
                })} ${getTimePeriodText(
                  campaign.attachFrom,
                  campaign.attachTo
                )}`}
              </Body>
            </li>
          )}
          {campaign.activeFrom && campaign.activeTo && (
            <li>
              <Body size="medium">
                {`${getCopy(
                  'campaigns__publish_dialog_active'
                )} ${getTimePeriodText(
                  campaign.activeFrom,
                  campaign.activeTo
                )}`}
              </Body>
            </li>
          )}
          <li>
            <Body size="medium">{getCopy('campaigns__can_be_edited')}</Body>
          </li>
        </StyledList>
        {overlappingCampaignsElement}
        {errorElement}
      </Flex>
    </DialogComponent>
  );
};

const StyledList = styled.ul({
  margin: 0,
  padding: [0, 0, 0, '$spacing-32'],
});
