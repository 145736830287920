import type { Campaign } from '@sdk/campaigns';

export type PublishableCampaign = Partial<Campaign> &
  Pick<
    Campaign,
    | 'activeFrom'
    | 'activeTo'
    | 'attachFrom'
    | 'attachTo'
    | 'documentType'
    | 'image'
    | 'position'
    | 'targetGroup'
  >;

export const isCampaignPublishable = (
  campaign: Partial<Campaign>
): campaign is PublishableCampaign =>
  Boolean(campaign.activeFrom) &&
  Boolean(campaign.activeTo) &&
  Boolean(campaign.attachFrom) &&
  Boolean(campaign.attachTo) &&
  Boolean(campaign.documentType) &&
  Boolean(campaign.image) &&
  Boolean(campaign.position) &&
  Boolean(campaign.targetGroup);
