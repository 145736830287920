import { createContext, useContext } from 'react';
import { Organization, SenderSearchResult } from '@kivra/senders';
import { getSender } from './utils';
import { useConfig } from './globalContext';

export interface SendersContext {
  senders: SenderSearchResult[];
  organization: Organization;
}

export const SendersContext = createContext<SendersContext>(null as any);

const {
  Consumer: SendersContextConsumer,
  Provider: SendersContextProvider,
} = SendersContext;

const hookCreator = <T>(map: (tc: SendersContext) => T) => (): T => {
  const sender = useContext(SendersContext);
  return map(sender);
};

export const useSenders = hookCreator((t) => t.senders);
export const useGetSenders = hookCreator((t) => getSender(t.senders));
export const useCurrentOrganization = hookCreator(({ organization }) => {
  const config = useConfig();
  const organizationId = organization.id;
  const isAdminOrganization =
    organizationId === config.sender_backoffice_admin_organization_id;
  return {
    organizationId,
    organizationName: organization.name,
    isAdminOrganization,
  };
});
export { SendersContextProvider, SendersContextConsumer };
