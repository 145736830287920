import React from 'react';
import type { TargetGroupType } from '@sdk/campaigns';
import { getCopy } from '../../../../../util/copy';
import { CampaignField } from './fieldType';
import { SelectField } from './SelectField';

export const TargetGroupField: CampaignField = props => {
  const targetOptions: { label: string; value: TargetGroupType }[] = [
    { label: getCopy('campaigns__target_group__all'), value: 'all' },
    { label: getCopy('campaigns__target_group__user'), value: 'user' },
    { label: getCopy('campaigns__target_group__company'), value: 'company' },
  ];
  return <SelectField name="targetGroup" options={targetOptions} {...props} />;
};
