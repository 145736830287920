import { Body, Flex, Heading, Margin } from '@kivra/react-components';
import React from 'react';
import { useSenders } from '../../context/sendersContext';
import { getCopy } from '../../util/copy';
import { sendersByTypeCopy } from '../../util/copyKeys';
import { ListItem } from './ListItem';
import { UserImage } from './UserImage';

interface Props {
  onSenderSelected: (senderKey: string) => void;
  activeSenderKey?: string;
  type: 'store' | 'tenant';
}

export const Senders = ({ onSenderSelected, activeSenderKey, type }: Props) => {
  const senders = useSenders();
  const hasMutlipleSenderTypes = senders.some(
    (s) => s.type !== senders[0]?.type
  );
  const sendersOfType = senders.filter((s) => s.type === type);

  if (sendersOfType.length === 0) {
    return null;
  }

  return (
    <>
      <Margin bottom={16} left={16} top={16}>
        <Heading size="small">{`${
          hasMutlipleSenderTypes
            ? getCopy(sendersByTypeCopy[type])
            : getCopy('campaigns__sender', 2)
        } (${sendersOfType.length})`}</Heading>
      </Margin>
      <div>
        {sendersOfType.map((s) => {
          const isActiveSender = s.key === activeSenderKey;
          const onClick = !isActiveSender
            ? () => onSenderSelected(s.key)
            : undefined;
          return (
            <ListItem key={s.key} onClick={onClick} isActive={isActiveSender}>
              <Flex alignItems="center">
                <UserImage src={s.iconUrl} size="small" />
                <Body size="medium">{s.name}</Body>
              </Flex>
            </ListItem>
          );
        })}
      </div>
    </>
  );
};
