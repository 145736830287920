import type { Campaign } from '@sdk/campaigns';
import React from 'react';
import { CampaignForm } from '../types/campaignForm';
import { PreviewType } from '../types/previewType';
import { PreviewContextProvider } from './previewContext';

type Props = {
  campaign: Campaign | CampaignForm;
  zoomRatio: number;
  previewType: PreviewType;
  children?: React.ReactNode;
};

export const PreviewProvider = ({
  campaign,
  zoomRatio,
  previewType,
  children,
}: Props) => {
  const getZoomRatioPixels = (pixelSize: number) => pixelSize * zoomRatio;
  return (
    <PreviewContextProvider
      value={{
        campaign,
        getZoomRatioPixelsString: (pixelSize: number) =>
          `${getZoomRatioPixels(pixelSize)}px`,
        getZoomRatioPixels,
        previewType,
      }}
    >
      {children}
    </PreviewContextProvider>
  );
};
