import React, { ChangeEvent, useState } from 'react';
import { css, TextField } from '@kivra/react-components';

type Props = {
  size: number;
  onChange: (size: number) => void;
  max?: number;
  min?: number;
};

export const FontSizeControl = ({
  max = 99,
  min = 12,
  onChange,
  size,
}: Props) => {
  const [inputValue, setInputValue] = useState(size.toString());
  const [error, setError] = useState(false);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const stringValue = e.target.value;
    const numericValue = parseInt(e.target.value);
    if (stringValue === '' || (numericValue <= max && numericValue >= 0)) {
      setInputValue(stringValue);
      if (numericValue >= min && numericValue <= max) {
        onChange(numericValue);
        setError(false);
      } else {
        setError(true);
      }
    }
  };
  return (
    <span
      className={css({
        input: {
          borderColor: error ? '$surface-error' : undefined,
          fontSize: 12,
          height: 28,
          padding: ['$spacing-4', '$spacing-8'],
          width: 50,
        },
      })}
    >
      <TextField
        size="small"
        type="number"
        value={inputValue}
        onChange={handleOnChange}
        ariaInvalid
      ></TextField>
    </span>
  );
};
