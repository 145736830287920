import React from 'react';
import { Body, TableCell, TableRow } from '@kivra/react-components';
import { TitleImageCell } from '../../../components/TitleImageCell';

export type RowData = {
  id: string;
  title: string;
  image: string | null;
  period: string;
  periodText: string;
  organization: string;
  sender: string;
  senderType: string;
};

interface Props {
  rowData: RowData;
  onClick: () => void;
  hiddenColumns: string[];
}

export const OrganizationCampaignRow = ({
  rowData,
  onClick,
  hiddenColumns,
}: Props) => {
  return (
    <TableRow onClick={onClick}>
      <TableCell>
        <TitleImageCell title={rowData.title} image={rowData.image} />
      </TableCell>
      <TableCell>
        <Body size="small">{rowData.periodText}</Body>
      </TableCell>
      {!hiddenColumns.includes('organization') && (
        <TableCell>
          <Body size="small">{rowData.organization}</Body>
        </TableCell>
      )}
      <TableCell>
        <Body size="small">{rowData.sender}</Body>
      </TableCell>
      {!hiddenColumns.includes('senderType') && (
        <TableCell>
          <Body size="small">{rowData.senderType}</Body>
        </TableCell>
      )}
    </TableRow>
  );
};
