import type { BannerConfig, ButtonOptions, TextOptions } from '@sdk/campaigns';
import { omit } from '@kivra/common';
import { styled } from '@kivra/react-components';
import React, { CSSProperties, ForwardedRef } from 'react';
import { HorizontalAlignment } from '../../types/alignment';
import { useEditorFonts } from './FontProvider';

type Props = {
  bannerOptions: BannerConfig;
};

export const BannerPreview = React.forwardRef(
  ({ bannerOptions }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Wrapper ref={ref}>
        <Background bannerOptions={bannerOptions}>
          <div>
            <Text textOptions={bannerOptions.title.options}>
              {bannerOptions.title.value}
            </Text>
            <Text textOptions={bannerOptions.body.options}>
              {bannerOptions.body.value}
            </Text>
          </div>
          <Button buttonOptions={bannerOptions.button.options}>
            {bannerOptions.button.value}
          </Button>
        </Background>
      </Wrapper>
    );
  }
);

const Background = ({
  bannerOptions,
  children,
}: {
  bannerOptions: BannerConfig;
  children?: React.ReactNode;
}) => (
  <div
    style={{
      backgroundColor: bannerOptions.backgroundColor.value,
      width: '100%',
      height: '100%',
      padding: '48px 40px 40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </div>
);

const Text = ({
  children,
  textOptions,
}: {
  textOptions: TextOptions;
  children?: React.ReactNode;
}) => {
  const { fonts } = useEditorFonts();
  const isUnsupportedFont = !fonts.includes(textOptions.font);
  return (
    <div
      style={{
        width: '100%',
        color: textOptions.color,
        textAlign: textOptions.alignment,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        ...textStyle({
          ...textOptions,
          font: isUnsupportedFont ? fonts[0] : textOptions.font,
        }),
      }}
    >
      {children}
    </div>
  );
};

const Button = ({
  buttonOptions,
  children,
}: {
  buttonOptions: ButtonOptions;
  children?: React.ReactNode;
}) => (
  <div
    style={{
      backgroundColor: buttonOptions.backgroundColor,
      alignSelf: alignmentToFlex[buttonOptions.alignment],
      padding: `0.6em 1.6em`,
      lineHeight: '1em',
      borderRadius: '2.2em',
      minHeight: '2.2em',
      ...textStyle(omit(buttonOptions, ['backgroundColor'])),
    }}
  >
    {children}
  </div>
);

const Wrapper = styled('div')({
  overflow: 'hidden',
  width: 640,
  height: 320,
});

const alignmentToFlex: Record<HorizontalAlignment, string> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const textStyle = (options: Partial<TextOptions>): CSSProperties => ({
  color: options.color,
  fontFamily: options.font,
  visibility: options.visible ? 'visible' : 'hidden',
  fontWeight: options.bold ? 'bold' : 'initial',
  textDecoration: options.underline ? 'underline' : 'none',
  fontStyle: options.italic ? 'italic' : 'normal',
  fontSize: options.size,
});
