import { TextField } from '@kivra/react-components';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CampaignForm } from '../../../../../types/campaignForm';
import { Block } from '../Block';
import { getBlockProps } from '../utils/getBlockProps';
import { CampaignField } from './fieldType';

export const TitleField: CampaignField = () => {
  const { control, getValues } = useFormContext<CampaignForm>();
  return (
    <Block {...getBlockProps('title', getValues())}>
      <Controller
        control={control}
        name="title"
        render={props => {
          return (
            <TextField
              dataTestId="CampaignTitleField"
              size="small"
              value={props.field.value}
              onTextChange={props.field.onChange}
              onBlur={props.field.onBlur}
            />
          );
        }}
      />
    </Block>
  );
};
