import {
  convertPropertyNamesToCamelCase,
  kampanhaRequest,
} from '@kivra/common';
import { userSession } from '@kivra/identity';
import type {
  SegmentationJobStatus,
  SegmentationJobInformation,
  SegmentationJobRequest,
} from './types';

/**
 * Creates a segmentation job in backend that processes a list of SSN.
 * Information needed to poll the job is returned.
 */
export const createSegmentationJob = (
  organizationId: string,
  senderKey: string,
  campaignKey: string,
  { filename, fileContent }: SegmentationJobRequest
): Promise<SegmentationJobInformation> => {
  return kampanhaRequest
    .post<any>({
      path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/${campaignKey}/segmentation/ssn?filename=${filename}`,
      accessToken: userSession.getToken(),
      headers: {
        ['Content-Type']: 'text/csv',
      },
      payload: fileContent,
    })
    .then(result => {
      return convertPropertyNamesToCamelCase(
        result
      ) as SegmentationJobInformation;
    });
};

/**
 * Fetches the segmentation job status.
 */
export const getSegmentationJobStatus = (
  organizationId: string,
  senderKey: string,
  jobKey: string
): Promise<SegmentationJobStatus> => {
  return kampanhaRequest
    .get<any>({
      path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/jobs/${jobKey}`,
      accessToken: userSession.getToken(),
    })
    .then(result => {
      return convertPropertyNamesToCamelCase(result) as SegmentationJobStatus;
    });
};

/**
 * Deletes a segmentation.
 */
export const deleteSegmentation = (
  organizationId: string,
  senderKey: string,
  campaignKey: string
): Promise<void> => {
  return kampanhaRequest.delete({
    path: `/v2/organization/${organizationId}/sender/${senderKey}/campaign/${campaignKey}/segmentation/ssn`,
    accessToken: userSession.getToken(),
  });
};
