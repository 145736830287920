import React from 'react';
import { RouteComponentProps } from 'react-router';
import { CampaignEditor } from '../editor/CampaignEditor';
import { CampaignDetails } from '../details/CampaignDetails';
import { CampaignPageType, goTo } from '../../routes/history';
import { useCreateSenderRoute, useSender } from '../../context/senderContext';
import { CampaignProvider } from '../../context/campaignProvider';
import { useCurrentOrganization } from '../../context/sendersContext';
import { useCampaign } from '../../context/campaignContext';

export interface ParamsCampaignId {
  campaignId: string;
  pageType: CampaignPageType;
}

export const CampaignPage = ({
  match,
}: RouteComponentProps<ParamsCampaignId>) => {
  const { organizationId } = useCurrentOrganization();
  const { campaignId, pageType } = match.params;
  const { key: senderKey } = useSender();
  const getSenderRoute = useCreateSenderRoute();

  return (
    <CampaignProvider
      id={campaignId}
      organizationKey={organizationId}
      senderKey={senderKey}
      onCampaignUpdate={({ id }) => {
        if (id !== campaignId) {
          goTo(
            getSenderRoute({ id: 'campaign', campaignId: id, pageType: 'edit' })
          );
        }
      }}
    >
      <CampaignView pageType={pageType} />
    </CampaignProvider>
  );
};

const CampaignView = ({ pageType }: { pageType: CampaignPageType }) => {
  const { campaign } = useCampaign(false);
  const shouldShowEditor =
    !campaign || (pageType === 'edit' && campaign?.clientStatus !== 'previous');
  return shouldShowEditor ? <CampaignEditor /> : <CampaignDetails />;
};
