import React from 'react';
import type { ClientStatusType } from '@sdk/campaigns';
import { css, TableRow, TableCell } from '@kivra/react-components';
import {
  CampaignTableColumns,
  CampaignTableProps,
} from '../../../../types/table';
import { CampaignActions } from '../../../../components/CampaignActions';
import { goTo } from '../../../../routes/history';
import { useCreateSenderRoute } from '../../../../context/senderContext';
import { useReloadCampaigns } from '../../../../context/campaignsContext';
import { campaignActionsByStatus } from '../../../../util/campaignActions';
import { CampaignCellMapper } from './CampaignCellMapper';

interface Props {
  tableRow: CampaignTableProps;
  visibleColumns: CampaignTableColumns[];
  campaignStatus: ClientStatusType;
}

export const CampaignRow = ({
  tableRow,
  visibleColumns,
  campaignStatus,
}: Props) => {
  const reloadCampaigns = useReloadCampaigns();
  const createRoute = useCreateSenderRoute();
  const pageType =
    campaignStatus === 'upcoming' || campaignStatus === 'draft'
      ? 'edit'
      : 'details';
  const onRowClick = () =>
    goTo(createRoute({ id: 'campaign', campaignId: tableRow.id, pageType }));
  return (
    <TableRow>
      {visibleColumns.map(column => {
        return (
          <TableCell
            key={column}
            className={css({ '&:hover': { cursor: 'pointer' } })}
            onClick={onRowClick}
          >
            <CampaignCellMapper column={column} tableRow={tableRow} />
          </TableCell>
        );
      })}
      <TableCell key={'edit'}>
        <CampaignActions
          campaignId={tableRow.id}
          menuActions={campaignActionsByStatus[campaignStatus]}
          onDeleteSuccess={reloadCampaigns}
          onUnpublishSuccess={reloadCampaigns}
        />
      </TableCell>
    </TableRow>
  );
};
