import type { Campaign } from '@sdk/campaigns';
import { SenderType } from '@kivra/types/sender';
import { CampaignForm } from '../../../../../types/campaignForm';

export const destinationUrlDefaultValue = 'https://';

const defaultReceiptCampaignFormValues: Omit<CampaignForm, 'submitActionType'> =
  {
    bannerConfig: null,
    dates: {
      attachFrom: null,
      attachTo: null,
      activeFrom: null,
      activeTo: null,
    },
    destinationUrl: destinationUrlDefaultValue,
    documentType: '',
    image: '',
    position: 'bottom',
    targetGroup: 'user',
    title: '',
    useSso: false,
    isSegmentationJobOngoing: false,
  };

const defaultTenantCampaignFormValues: Omit<CampaignForm, 'submitActionType'> =
  {
    bannerConfig: null,
    dates: {
      attachFrom: null,
      attachTo: null,
      activeFrom: null,
      activeTo: null,
    },
    destinationUrl: destinationUrlDefaultValue,
    documentType: '',
    image: '',
    position: 'top',
    targetGroup: 'all',
    title: '',
    useSso: false,
    isSegmentationJobOngoing: false,
  };

const getDate = (dateString: string | null) =>
  dateString ? new Date(dateString) : null;

export const createCampaignForm = (
  senderType: SenderType,
  campaign?: Campaign
): CampaignForm => {
  const formCampaignValues = campaign
    ? {
        bannerConfig: campaign.bannerConfig,
        dates: {
          activeFrom: getDate(campaign.activeFrom),
          activeTo: getDate(campaign.activeTo),
          attachFrom: getDate(campaign.attachFrom),
          attachTo: getDate(campaign.attachTo),
        },
        clientStatus: campaign.clientStatus,
        destinationUrl: campaign.destinationUrl || destinationUrlDefaultValue,
        documentType: campaign.documentType,
        image: campaign.image || '',
        isSegmentationJobOngoing: campaign.segmentation?.status === 'ongoing',
        position: campaign.position,
        targetGroup: campaign.targetGroup,
        title: campaign.title,
        useSso: campaign.useSso,
      }
    : createLocalCampaignDraft(senderType);
  return {
    ...formCampaignValues,
    submitActionType: 'save',
  };
};

const createLocalCampaignDraft = (
  senderType: SenderType
): Omit<CampaignForm, 'submitActionType'> => {
  switch (senderType) {
    case 'chain':
    case 'store':
      return defaultReceiptCampaignFormValues;
    case 'tenant':
    default:
      return defaultTenantCampaignFormValues;
  }
};
