import React, { ChangeEvent, ReactElement, useRef } from 'react';
import { Button, Flex } from '@kivra/react-components';
import { getCopy } from '../../../../util/copy';

type ValidFileTypes = '.csv' | '.png,.jpeg,.jpg';

interface Props<ReturnType> {
  handleFiles: (files: FileList) => Promise<ReturnType>;
  validFileTypes: ValidFileTypes;
  onChange: (change: ReturnType) => void;
  disabled?: boolean;
}

export const UploadButton: <ReturnType>(
  props: Props<ReturnType>
) => ReactElement<Props<ReturnType>> = (props) => {
  const { disabled, onChange, handleFiles, validFileTypes } = props;
  const inputFileLoader = useRef<HTMLInputElement>(null);
  const uploadContentClick = () => {
    if (inputFileLoader?.current) {
      inputFileLoader.current.click();
    }
  };

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      handleFiles(files).then(onChange);
    }
  };

  return (
    <Flex justifyContent="center">
      <Button
        disabled={disabled}
        variant="secondary"
        onClick={uploadContentClick}
      >
        {getCopy('campaigns__choose_file')}
      </Button>
      <input
        type="file"
        accept={validFileTypes}
        onChange={onFilesChange}
        ref={inputFileLoader}
        style={{ display: 'none' }}
      />
    </Flex>
  );
};
