import React from 'react';
import { Caption, Flex, styled } from '@kivra/react-components';
import { getCopy } from '../../../../util/copy';
import { BlockTooltip } from './BlockTooltip';

export interface BlockProps {
  tooltipText?: string;
  label?: string;
  optional?: boolean;
  dataTestId?: string;
  children?: React.ReactNode;
}

export const Block = ({
  children,
  dataTestId,
  label,
  optional,
  tooltipText,
}: BlockProps) => {
  return (
    <Root data-component-type="Block" data-test-id={dataTestId}>
      <Flex justifyContent="space-between">
        <Caption>{label}</Caption>
        {optional && (
          <Caption style={{ fontStyle: 'italic' }}>
            {getCopy('campaigns__optional')}
          </Caption>
        )}
      </Flex>
      {tooltipText && <BlockTooltip tooltipText={tooltipText} />}
      {children}
    </Root>
  );
};

const Root = styled.div({
  position: 'relative',
  padding: ['$spacing-16', '$spacing-24'],
  borderTop: `1px solid $border-distinct`,
  '&:first-child': {
    borderTop: 'none',
  },
});
